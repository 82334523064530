import * as React from 'react';
export const ProjectsIcon = (props) => (
  <svg
    width={25}
    height={25}
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.8955 22.5142H5.89551C3.89551 22.5142 2.89551 21.5142 2.89551 19.5142V11.5142C2.89551 9.51416 3.89551 8.51416 5.89551 8.51416H10.8955V19.5142C10.8955 21.5142 11.8955 22.5142 13.8955 22.5142Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.0055 4.51416C10.9255 4.81416 10.8955 5.14416 10.8955 5.51416V8.51416H5.89551V6.51416C5.89551 5.41416 6.79551 4.51416 7.89551 4.51416H11.0055Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8955 8.51416V13.5142'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.8955 8.51416V13.5142'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.8955 17.5142H15.8955C15.3455 17.5142 14.8955 17.9642 14.8955 18.5142V22.5142H18.8955V18.5142C18.8955 17.9642 18.4455 17.5142 17.8955 17.5142Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.89551 13.5142V17.5142'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.8955 19.5142V5.51416C10.8955 3.51416 11.8955 2.51416 13.8955 2.51416H19.8955C21.8955 2.51416 22.8955 3.51416 22.8955 5.51416V19.5142C22.8955 21.5142 21.8955 22.5142 19.8955 22.5142H13.8955C11.8955 22.5142 10.8955 21.5142 10.8955 19.5142Z'
      stroke='currentColor'
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
 
