// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for profile details
const initialState = {
  profileData: null,
  loading: false,
  error: null,
};

// Get Profile Details API Function
export const getProfileDetailFunction = createAsyncThunk(
  'user/getProfileDetails',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.GetProfileDetails);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Profile Details API Function
export const updateProfileDetailFunction = createAsyncThunk(
  'user/udateProfileDetails',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(endPoints.UpdateProfileDetail, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const profileDetailsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },

  extraReducers: (builder) => {
    // Get Profile Details Reducers
    builder
      .addCase(getProfileDetailFunction.pending, (state) => {
        state.loading = true;
        state.profileData = null;
        state.error = null;
      })
      .addCase(getProfileDetailFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
        state.error = null;
      })
      .addCase(getProfileDetailFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.profileData = null;
      })
      .addCase(updateProfileDetailFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileDetailFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.profileData = action.payload;
      })
      .addCase(updateProfileDetailFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default profileDetailsSlice.reducer;
