import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { CustomModal, PartnerCard, PartnersProfileButton, Skeleton } from '../../../../components/shared';
import { BagIcon, ChatIcon, CrossIcon, LocationIcon, TrueIcon } from '../../../../assets/icons';
import { getPartnerDetailsFunction, getPartnersListingFunction, updatePartnerStatusFunction } from '../../../../redux/reducers/userReducers/partnersReducer/partnersReducer';
import { capitalize } from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';

export const NearestPartners = () => {
  const dispatch = useDispatch();
  const { partnersLoading, partners: partnersListing, partnerDetailsLoading } = useSelector((state) => state?.partners);
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [partnerDetail, setPartnerDetail] = useState(null);

  // Handle Partner Details Modal
  const handleCloseModal = () => {
    setOpenPartnerModal(false);
  };

  // Handle Status Functions
  const handleStatusChange = (status, id) => {
    setStatus(status);
    updatePartnerStatus(status, id);
  };

  // Update Partner Status
  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(updatePartnerStatusFunction({
      id: id,
      status: status?.toUpperCase()
    }));

    if (data?.meta?.requestStatus === 'fulfilled') {
      handleCloseModal();
      await dispatch(getPartnersListingFunction());
    }
  }

  // Get Partner Details Function
  const getPartnerDetails = async (id) => {
    const data = await dispatch(getPartnerDetailsFunction({
      id: id
    }));
    setPartnerDetail(data?.payload);
  }
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 base:grid-cols-3 gap-4 pb-6 mt-6">
      {partnersLoading ? (
        <>
          {Array.from({ length: 8 }, (_, index) => (
            <Skeleton key={index} className="w-full h-24 rounded-3xl" />
          ))}
        </>
      ) : (
        <>
          {partnersListing?.nearest_partners?.length > 0 ? (
            <>
              {partnersListing?.nearest_partners?.map((item) => {
                const location = [];
                location?.push(item?.mailing_address?.city?.name + ", " + item?.mailing_address?.state?.name);
                return (
                  <PartnerCard
                    key={item?.id}
                    src=""
                    name={(item?.first_name || "") + " " + (item?.last_name || "")}
                    designation={item?.profession}
                    address={location?.filter(Boolean)?.join(", ")}
                    onClick={() => {
                      // setOpenPartnerModal(true);
                      // getPartnerDetails(item?.id);
                      navigate(`/partners/partner-detail/${item?.id}`);
                    }}
                  />
                )
              })}
            </>
          ) : (
            <p className='text-center font-semibold sm:col-span-2 base:col-span-3'>No Partners Found</p>
          )}
        </>
      )}

      <CustomModal
        title={"Darius Wright, 32"}
        open={openPartnerModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        {partnerDetailsLoading ? (
          <>
            <div className="font-nunito pb-3 flex justify-between items-center">
              <Skeleton className="flex rounded-full w-20 h-3" />
              <Skeleton className="flex rounded-full w-20 h-3" />
            </div>

            <div className='flex flex-col gap-2'>
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-20 h-3" />
            </div>

            <div className='center flex-col gap-2'>
              <Skeleton className="flex rounded-full w-32 h-6 mb-4" />

              <div className='vertical-center gap-2'>
                <Skeleton className="flex rounded-full w-32 h-3" />
                <Skeleton className="flex rounded-full w-32 h-3" />
              </div>
            </div>

            <div className='center gap-2 mt-6'>
              <Skeleton className="flex rounded-md size-20" />
              <Skeleton className="flex rounded-md size-20" />
              {/* <Skeleton className="flex rounded-md size-20" /> */}
            </div>
          </>
        ) : (
          <>
            <div className="font-nunito pb-3 flex justify-between items-center">
              {partnerDetail?.profession ? (
                <div className="flex justify-center items-center gap-1">
                  <BagIcon color={"#85838F"} />
                  <p className="font-bold text-base text-darkGray">{partnerDetail?.profession}</p>
                </div>
              ) : null}
              <div className="flex justify-center items-center gap-1 ml-auto bg-gold/10 px-2.5 py-[3px] rounded-[20px]">
                <LocationIcon color={"#D89B00"} />
                <p className="font-bold text-sm text-gold">San Francisco, CA</p>
              </div>
            </div>

            <p className=" font-nunito text-sm pb-3">
              {`${capitalize(partnerDetail?.first_name)} ${capitalize(partnerDetail?.last_name)}`} is a pioneering figure in the real estate development
              industry, renowned for her innovative approach to sustainable and
              community-focused projects. With over two decades of experience,
              Darius has redefined urban landscapes across the nation, blending
              modern architectural aesthetics with eco-friendly practices.
            </p>

            <div className="font-nunito flex flex-col justify-center items-center pb-3">
              <span className="text-2xl font-extrabold text-primary pb-2 ">
                $150-$200/h
              </span>
              <div className="flex gap-3">
                <span className="text-gold text-sm bg-gold/10 px-2.5 py-[3px] rounded-[20px]">
                  Negotiable
                </span>
                <span className="text-gold text-sm bg-gold/10 px-2.5 py-[3px] rounded-[20px]">
                  Fully Remote
                </span>
              </div>
            </div>

            <div className="flex justify-center items-center gap-5">
              <PartnersProfileButton
                icon={<CrossIcon color={"red"} height={24} width={24} />}
                value={"deprioritize"}
                selectedValue={status}
                onChange={(event) => handleStatusChange(event.target.value, partnerDetail?.id)}
                label="Deprioritize"
              />
              <PartnersProfileButton
                icon={<TrueIcon color={"#1E009F"} />}
                value={"match"}
                selectedValue={status}
                onChange={(event) => handleStatusChange(event.target.value, partnerDetail?.id)}
                label="Match"
              />
              <PartnersProfileButton
                icon={<ChatIcon color={"#D89B00"} height={21} width={21} />}
                value={"prioritize"}
                selectedValue={status}
                onChange={(event) => handleStatusChange(event.target.value, partnerDetail?.id)}
                label="Prioritize"
              />
            </div>
          </>
        )}
      </CustomModal>
    </div>
  )
};