import React from 'react'

export const Divider = ({ text }) => {
  return (
    <div className={`relative ${text ? 'my-3' : ''}`}>
      <div className="absolute inset-0 vertical-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center text-sm">
        {text ? (
          <span className="bg-white px-2 text-gray-400 font-nunito text-xs">{text}</span>
        ) : null}
      </div>
    </div>
  )
};