import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

// Local Imports
import { Divider, TextInput, CustomModal, showToast, Skeleton, SelectDropdown } from '../../../../components/shared';
import { BagIcon, BirthdayIcon, EditIcon, LocationIcon, LongArrow, MailIcon, PhoneIcon } from '../../../../assets/icons';
import { isEmpty, isError, validateEmail } from '../../../../utils/validations';
import { getProfileDetailFunction, updateProfileDetailFunction } from '../../../../redux/reducers/userReducers/editProfileReducers/editProfileReducer';
import { capitalize, formatDate } from '../../../../utils/utils';
import { assets } from '../../../../assets/images';
import { rolesAndInterestsFunction } from '../../../../redux/reducers/authReducers/rolesInterestsReducer';
import { citiesFunction, statesFunction } from '../../../../redux/reducers/authReducers/statesReducer';

// ProfileDetails Initial Data 
const initialProfileData = {
  firstName: '',
  lastName: '',
  address: '',
  physicalAddress: '',
  profession: '',
  gender: '',
  birthday: '',
  phoneNumber: '',
  email: '',
  interests: [],
  roles: [],
  currentState: [],
  currentCity: [],
  currentZipCode: '',
  operatedState: [],
  operatedCity: [],
  operatedZipCode: '',
}

// ProfileDetails Initial Errors 
const initialProfileErrors = {
  firstName: '',
  lastName: '',
  address: '',
  physicalAddress: '',
  profession: '',
  gender: '',
  birthday: '',
  phoneNumber: '',
  email: '',
}

export const ProfileDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, profileData: profileDetails } = useSelector((state) => state.profileDetails);
  const { cityLoading } = useSelector((state) => state?.states);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [profileData, setProfileData] = useState(initialProfileData);
  const [formErrors, setFormErrors] = useState(initialProfileErrors);
  const [statesListing, setStatesListing] = useState([]);
  const [currentCityList, setCurrentCityList] = useState([]);
  const [operatedCityList, setOperatedCityList] = useState([]);

  const getRolesInterests = async () => {
    const rolesAndInterest = await dispatch(rolesAndInterestsFunction());
    const statesList = await dispatch(statesFunction());

    // Create Roles Array
    const roles = await rolesAndInterest?.payload?.roles?.reduce((prev, curr) => (
      [...prev, { id: curr?.id, name: curr?.name?.toLowerCase(), label: curr?.name, value: false }]
    ), [])

    // Create Inetrest Array
    const interests = await rolesAndInterest?.payload?.interests?.reduce((prev, curr) => (
      [...prev, { id: curr?.id, name: curr?.name?.toLowerCase(), label: curr?.name, value: false }]
    ), [])

    setProfileData(prev => ({
      ...prev,
      roles: roles,
      interests: interests
    }))

    // Set States
    setStatesListing(statesList?.payload)
  }

  // Use Layout Effect
  useLayoutEffect(() => {
    getRolesInterests();
  }, [])


  const handleCloseModal = () => {
    setOpenProfileModal(false);
    setFormErrors(initialProfileErrors);
  };

  const handleOpenModal = () => {
    setProfileData(prev => ({
      ...prev,
      firstName: profileDetails?.first_name,
      lastName: profileDetails?.last_name,
      physicalAddress: profileDetails?.mailing_address?.physical_address || '',
      profession: profileDetails?.profession || '',
      gender: profileDetails?.gender?.toLowerCase() || '',
      birthday: profileDetails?.birthday || '',
      phoneNumber: profileDetails?.phone_no || '',
      interests: profileDetails?.user_interests || [],
      role: profileDetails?.user_roles || [],
      address: profileDetails?.mailing_address?.address || '',
      currentState: `${profileDetails?.mailing_address?.state?.id}` || '',
      currentCity: `${profileDetails?.mailing_address?.city?.id}` || '',
      currentZipCode: profileDetails?.mailing_address?.zipcode || '',
      operatedState: `${profileDetails?.operated_address?.state?.id}` || '',
      operatedCity: `${profileDetails?.operated_address?.city?.id}` || '',
      operatedZipCode: profileDetails?.operated_address?.zipcode || '',
    }));
    setOpenProfileModal(true);
  };

  const getProfileDetail = async () => {
    await dispatch(getProfileDetailFunction());
  }

  useLayoutEffect(() => {
    getProfileDetail();
  }, []);

  // Get Cities Listing 
  const getCities = async (from) => {
    if (from === 'current') {
      const citiesList = await dispatch(citiesFunction({ id: profileData?.currentState }));
      setCurrentCityList(citiesList?.payload);
    }
    if (from === 'operated') {
      const citiesList = await dispatch(citiesFunction({ id: profileData?.operatedState }));
      setOperatedCityList(citiesList?.payload);
    }
  }

  useEffect(() => {
    if (profileData?.currentState?.length > 0) {
      getCities('current');
    }
  }, [profileData?.currentState])

  useEffect(() => {
    if (profileData?.operatedState?.length > 0) {
      getCities('operated');
    };
  }, [profileData?.operatedState])

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleSave = async () => {
    if (!validateEmail(profileData?.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid Email',
      }))
    }

    if (isError(formErrors)) {
      return;
    }

    const data = await dispatch(updateProfileDetailFunction({
      first_name: profileData?.firstName,
      last_name: profileData?.lastName,
      phone_no: profileData?.phoneNumber,
      profession: profileData?.profession || null,
      gender: profileData?.gender?.toUpperCase() || null,
      birthday: profileData?.birthday || null,
      location: {
        address: profileData?.address || null,
        physical_address: profileData?.physicalAddress || null,
        city: profileData?.currentCity,
        postalcode: profileData?.currentZipCode,
      },
      operated_location: {
        city: profileData?.operatedCity,
        postalcode: profileData?.operatedZipCode,
      }
    }));

    if (data?.meta?.requestStatus === 'rejected') {
      const errorMessages = data?.payload ? data?.payload?.map((error) => Object.values(error).join())?.join('\n') : 'Something went wrong';
      showToast(errorMessages, 'error', { position: 'top-center' });
      return false;
    }

    if (data?.meta?.requestStatus === 'fulfilled') {
      showToast('Profile Details Updated Successfully', 'success', {
        position: 'top-center',
      });
      handleCloseModal();
      getProfileDetail();
    }
  };

  return (
    <div>
      <section className='font-nunito mt-4 bg-white rounded-xl'>
        <div className="relative pb-10">
          <div className='z-10 relative pl-6 py-6 pb-[4.5rem]'>
            <div className='vertical-center gap-3'>
              <LongArrow
                className='rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition'
                onClick={() => navigate(-1)}
              />
              <h3 className='text-xl'>Profile</h3>
            </div>
          </div>
          <img src={assets?.coverbg} loading='lazy' alt="cover-image" className="w-full absolute top-0 left-0 z-0 h-48 object-cover rounded-t-xl" />
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-baseline justify-center sm:justify-between relative z-10 mb-5">
              <div className='vertical-center justify-center border-4 bg-white border-white rounded-full z-10'>
                {loading ? (
                  <Skeleton className='size-32 rounded-full' />
                ) : (
                  <Avatar
                    className='!size-32'
                    color="secondary"
                  />
                )}
              </div>
              <div
                className="hidden sm:vertical-center gap-2 font-semibold cursor-pointer select-none hover:text-secondary transition"
                onClick={handleOpenModal}
              >
                {loading ? (
                  <Skeleton className='w-28 h-6 rounded-full' />
                ) : (
                  <>
                    {profileDetails ? (
                      <>
                        <EditIcon />
                        <p>Edit Profile</p>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div className='flex flex-col items-center sm:items-start'>
                {loading ? (
                  <>
                    <Skeleton className='w-40 h-6 rounded-full mb-3' />
                    <Skeleton className='w-36 h-4 rounded-full' />
                  </>
                ) : (
                  <h3 className="font-manrope font-bold text-2xl">{capitalize(profileDetails?.first_name) + " " + capitalize(profileDetails?.last_name)}</h3>
                )}
                {profileDetails?.mailing_address ? (
                  <div className='vertical-center gap-2 text-grey'>
                    <LocationIcon width={16} height={16} />
                    <p className="font-normal text-base leading-7 text-grey">{profileDetails?.mailing_address?.city?.name + ", " + profileDetails?.mailing_address?.state?.name + ", " + profileDetails?.mailing_address?.zipcode}</p>
                  </div>
                ) : null}
              </div>
              <div
                className="sm:hidden center gap-2 font-semibold cursor-pointer hover:text-secondary transition"
                onClick={handleOpenModal}
              >
                {loading ? (
                  <Skeleton className='w-28 h-6 rounded-full' />
                ) : (
                  <>
                    <EditIcon />
                    <p>Edit Profile</p>
                  </>
                )}
              </div>

              {loading ? (
                <Skeleton className='w-44 h-12 rounded-full' />
              ) : (
                <>
                  {profileDetails?.profession ? (
                    <div
                      className="rounded-full py-3 px-4 bg-bggrey/80 hover:bg-primary/20 hover:text-secondary vertical-center transition">
                      <BagIcon />
                      <span className="px-2 font-medium text-base leading-7">{profileDetails?.profession}</span>
                    </div>
                  ) : null}
                </>
              )}
            </div>

            <div className='smd:grid grid-cols-12 gap-1'>
              <div className='flex flex-col gap-4 w-full col-start-1 col-span-6 max-smd:mt-10'>
                {loading ? (
                  <>
                    <Skeleton className='w-60 h-3 rounded-full' />
                    <Skeleton className='w-44 h-3 rounded-full' />
                    <Skeleton className='w-60 h-3 rounded-full' />
                    <Skeleton className='w-28 h-3 rounded-full' />
                  </>
                ) : (
                  <>
                    <div className='vertical-center gap-2 text-darkgrey'>
                      <MailIcon width={18} height={18} />
                      <p className='text-[15px] leading-4'>{profileDetails?.email}</p>
                    </div>

                    <div className='vertical-center gap-2 text-darkgrey'>
                      <PhoneIcon width={19} height={19} />
                      <p className='text-sm leading-4'>{profileDetails?.phone_no}</p>
                    </div>

                    {profileDetails?.operated_address ? (
                      <div className='vertical-center gap-2 text-darkgrey'>
                        <LocationIcon width={16} height={16} />
                        <p className="font-normal text-sm leading-4 text-darkgrey">{profileDetails?.operated_address?.city?.name + ", " + profileDetails?.operated_address?.state?.name + ", " + profileDetails?.operated_address?.zipcode}</p>
                      </div>
                    ) : null}

                    {profileDetails?.birthday ? (
                      <div className='vertical-center gap-2 text-darkgrey'>
                        <BirthdayIcon width={19} height={19} />
                        <p className='text-sm leading-4'>{formatDate(profileDetails?.birthday)}</p>
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              <div className='w-full max-smd:mt-10 col-span-7 smd:col-span-5 md:col-span-4 lg:col-span-3 col-start-7 smd:col-start-8 md:col-start-9 lg:col-start-10'>
                <div>
                  <p className='text-md font-semibold'>Role</p>
                  <Divider />
                  <div className='mt-3 flex flex-wrap items-center gap-2'>
                    {loading ? (
                      <Skeleton className="flex rounded-full w-24 h-6" />
                    ) : (
                      <>
                        {profileDetails?.user_roles && profileDetails?.user_roles.map((role, index) => (
                          <p key={index} className='text-sm bg-gold/30 rounded-full w-fit px-3 py-1'>{role}</p>
                        ))}
                      </>
                    )}

                  </div>
                </div>
                <div className='mt-4'>
                  <p className='text-md font-semibold'>Interests</p>
                  <Divider />
                  <div className='mt-3 flex flex-wrap items-center gap-2'>
                    {loading ? (
                      <>
                        {Array.from({ length: 3 }, (_, index) => (
                          <Skeleton key={index} className="flex rounded-full w-24 h-6" />
                        ))}
                      </>
                    ) : (
                      <>
                        {profileDetails?.user_interests && profileDetails?.user_interests?.map((interest, index) => (
                          <p key={index} className='text-sm bg-gold/30 rounded-full w-fit px-3 py-1'>{interest}</p>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <CustomModal
        title={'Edit Profile'}
        open={openProfileModal}
        close={handleCloseModal}
        primaryButtonClick={handleSave}
        secondaryButtonClick={handleCloseModal}
        primaryButtonText={"Update"}
        primaryButtonLoading={loading}
        primaryButtonDisabled={isEmpty({
          firstName: profileData?.firstName,
          lastName: profileData?.lastName,
          phoneNumber: profileData?.phoneNumber,
          // interests: [],
          // roles: [],
          currentState: profileData?.currentState,
          currentCity: profileData?.currentCity,
          currentZipCode: profileData?.currentZipCode,
          operatedState: profileData?.operatedState,
          operatedCity: profileData?.operatedCity,
          operatedZipCode: profileData?.operatedZipCode,
        })}
      >
        <form onSubmit={handleSave}>
          <div className='flex flex-col gap-5'>
            <h2 className='text-lg font-bold text-gray-800'>Personal Details</h2>
            <TextInput
              label='First Name'
              name='firstName'
              value={profileData.firstName}
              onChange={handleFormDataChange}
              error={formErrors.firstName}
              errorMsg={formErrors.firstName}
              required={true}
            />
            <TextInput
              label='Last Name'
              name='lastName'
              value={profileData.lastName}
              onChange={handleFormDataChange}
              error={formErrors.lastName}
              errorMsg={formErrors.lastName}
              required={true}
            />
            <TextInput
              label='Address'
              name='address'
              value={profileData.address}
              onChange={handleFormDataChange}
              error={formErrors.address}
              errorMsg={formErrors.address}
            />
            <TextInput
              label='Physical Address'
              name='physicalAddress'
              value={profileData.physicalAddress}
              onChange={handleFormDataChange}
              error={formErrors.physicalAddress}
              errorMsg={formErrors.physicalAddress}
            />
            <TextInput
              label='Profession'
              name='profession'
              value={profileData.profession}
              onChange={handleFormDataChange}
              error={formErrors.profession}
              errorMsg={formErrors.profession}
            />
            <div className='vertical-center space-x-4 '>
              <label className='font-semibold'>Gender:</label>
              <label>
                <input
                  type='radio'
                  name='gender'
                  value='male'
                  checked={profileData.gender === 'male'}
                  onChange={handleFormDataChange}
                />
                <span className='pl-1'>Male</span>
              </label>
              <label>
                <input
                  type='radio'
                  name='gender'
                  value='female'
                  checked={profileData.gender === 'female'}
                  onChange={handleFormDataChange}
                />
                <span className='pl-1'>Female</span>
              </label>
            </div>
            <TextInput
              label='Birthday'
              name='birthday'
              type='date'
              value={profileData.birthday}
              onChange={handleFormDataChange}
              error={formErrors.birthday}
              errorMsg={formErrors.birthday}
            />
          </div>

          <div className='my-4'>
            <p className='text-md font-semibold'>Current Location</p>
            <Divider />
          </div>

          <div className='flex flex-col gap-5 mb-2'>
            <SelectDropdown
              options={statesListing}
              loading={true}
              required={true}
              label='Select State'
              name='currentState'
              value={profileData?.currentState}
              onChange={handleFormDataChange}
            />

            <SelectDropdown
              options={currentCityList}
              disabled={!profileData?.currentState}
              loading={true}
              required={true}
              label='Select City'
              name='currentCity'
              value={profileData?.currentCity}
              onChange={handleFormDataChange}
              description={cityLoading ? 'Loading...' : !profileData?.currentState ? 'Please Select State First' : ""}
            />

            <TextInput
              label='Zip Code'
              type='number'
              name='currentZipCode'
              required={true}
              value={profileData?.currentZipCode}
              onChange={handleFormDataChange}
            />
          </div>

          <div className='my-4'>
            <p className='text-md font-semibold'>Operated Location</p>
            <Divider />
          </div>

          <div className='flex flex-col gap-5 mb-2'>
            <SelectDropdown
              options={statesListing}
              loading={true}
              required={true}
              label='Select State'
              name='operatedState'
              value={profileData?.operatedState}
              onChange={handleFormDataChange}
            />

            <SelectDropdown
              options={operatedCityList}
              disabled={!profileData?.operatedState}
              required={true}
              label='Select City'
              name='operatedCity'
              value={profileData?.operatedCity}
              onChange={handleFormDataChange}
              description={cityLoading ? 'Loading...' : !profileData?.operatedState ? 'Please Select State First' : ""}
            />

            <TextInput
              label='Zip Code'
              type='number'
              name='operatedZipCode'
              required={true}
              value={profileData?.operatedZipCode}
              onChange={handleFormDataChange}
            />
          </div>


          <div className='flex flex-col gap-5'>
            <h2 className='text-lg font-bold'>Contacts</h2>
            <TextInput
              label='Phone Number'
              name='phoneNumber'
              type='number'
              value={profileData.phoneNumber}
              onChange={handleFormDataChange}
              error={formErrors.phoneNumber}
              errorMsg={formErrors.phoneNumber}
              required={true}
            />
          </div>
        </form>
      </CustomModal>
    </div>
  );
};
