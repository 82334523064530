// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for Signup Suggestions
const initialState = {
  signupSuggestions: [],
  loading: false,
  error: null,
};

// Get Signup Suggestions API Function
export const getSignupSuggestionsFunction = createAsyncThunk(
  'user/getSignupSuggestions',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.SignupSuggestions);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);


export const signupSuggestionsSlice = createSlice({
  name: 'user/signupSuggestions',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // Get Signup Suggestions Reducers
    builder
      .addCase(getSignupSuggestionsFunction.pending, (state) => {
        state.loading = true;
        state.signupSuggestions = null;
        state.error = null;
      })
      .addCase(getSignupSuggestionsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.signupSuggestions = action.payload;
        state.error = null;
      })
      .addCase(getSignupSuggestionsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.signupSuggestions = null;
      });
  },
});

export default signupSuggestionsSlice.reducer;
