// Library Imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Local Imports
import { Button } from '../../../components/shared';
import { AuthLayout } from '../../../components/shared';
import { assets } from '../../../assets/images';

const Splash = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <div className='pt-60 px-4 xs:pl-8 sm:pl-12 smd:pl-16 base:pl-20 xl:pl-28 flex flex-col items-center sm:items-start text-center sm:text-left font-nunito'>
        <p className='text-white text-2xl sm:text-2xl base:text-3xl w-fit'>Welcome to</p>
        <div className='font-nunito flex items-baseline justify-center my-4'>
          <img src={assets.logo} alt="logo" className='size-20 sm:size-24 base:size-32' />
          <p className='text-primary mt-3 text-5xl sm:text-5xl base:text-7xl font-bold tracking-wide w-fit'>ealNest.</p>
        </div>
        <p className='text-primary mt-5 text-lg sm:text-xl font-light tracking-wide w-fit'>Do more by easily partnering to boost your business goals.</p>
      </div>

      <div className='mx-4 xs:ml-8 sm:ml-12 smd:ml-16 base:ml-20 xl:ml-28 mt-20 flex flex-col items-center sm:flex-row  gap-5'>
        <div className='w-full xs:w-9/12 sm:w-36'>
          <Button
            variant={'filled'}
            color={'primary'}
            onClick={() => navigate('/signup')}
          >
            Join Now!
          </Button>
        </div>
        <div className='w-full xs:w-9/12 sm:w-36'>
          <Button
            variant={'outlined'}
            color={'gold'}
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
        </div>
      </div>
    </AuthLayout>
  )
}

export default Splash