import * as React from 'react';
export const TrueIcon = ({ width, height, color, ...props }) => (
<svg
    width={height ||21}
    height={height ||15}
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5048 0.317886C18.8815 -0.0859679 19.5143 -0.107962 19.9181 0.268761C20.2909 0.616506 20.3383 1.18243 20.0471 1.58508L19.9672 1.68212L8.09524 14.4091C7.74469 14.7849 7.17312 14.8296 6.77048 14.532L6.67357 14.4504L0.309569 8.3764C-0.0899518 7.99509 -0.10471 7.36209 0.276605 6.96257C0.628588 6.59378 1.19502 6.55284 1.59431 6.84864L1.69043 6.92961L7.322 12.304L18.5048 0.317886Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
