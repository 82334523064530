import React, { useLayoutEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// icons imports
import { Filter } from "../../../assets/icons";

// Local Imports
import {
  Chip,
  CustomPopover,
  Divider,
  SelectDropdown,
  SignupInterestCheckbox,
  TextInput,
} from "../../../components/shared";
import { PartnersIcon } from "../../../assets/icons";
import {
  filterPartnersFunction,
  getPartnersListingFunction,
} from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import {
  citiesFunction,
  statesFunction,
} from "../../../redux/reducers/authReducers/statesReducer";
import { rolesAndInterestsFunction } from "../../../redux/reducers/authReducers/rolesInterestsReducer";

// Initial Filters Data
const filtersData = {
  state: "",
  city: "",
  interests: [],
  profession: "",
  skill: "",
};

const Partner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { cityLoading } = useSelector((state) => state?.states);

  const [openPopover, setOpenPopover] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [formData, setFormData] = useState(filtersData);

  const getPartnersListing = async () => {
    await dispatch(getPartnersListingFunction());
  };

  useLayoutEffect(() => {
    getPartnersListing();
    fetchStates();
  }, []);

  const fetchStates = async () => {
    const statesResponse = await dispatch(statesFunction());
    setStatesList(statesResponse?.payload || []);
  };

  const fetchCities = async (stateId) => {
    if (!stateId) return;
    const citiesList = await dispatch(citiesFunction({ id: stateId }));
    setCityList(citiesList?.payload || []);
  };

  const handleStateChange = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity("");
    setFormData({ ...formData, state: value, city: "" });
    fetchCities(value);
  };

  const handleCityChange = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
    setFormData({ ...formData, city: value });
  };

  const handleInterestsChange = (name) => {
    // Create a copy of the current interests array
    const updatedInterests = [...formData?.interests];

    // Check if the interest is already selected
    const interestIndex = updatedInterests.indexOf(name);

    // If the interest is not selected, add it to the array
    if (interestIndex === -1) {
      updatedInterests.push(name);
    } else {
      // If the interest is already selected, remove it from the array
      updatedInterests.splice(interestIndex, 1);
    }

    // Update the formData with the new list of selected interests
    setFormData((prev) => ({
      ...prev,
      interests: updatedInterests,
    }));
  };

  const [interests, setInterests] = useState([]);
  const getRolesInterests = async () => {
    try {
      const rolesAndInterest = await dispatch(rolesAndInterestsFunction());
      const interestsArray = rolesAndInterest?.payload?.interests?.reduce(
        (prev, curr) => [
          ...prev,
          {
            id: curr?.id,
            name: curr?.name?.toLowerCase(),
            label: curr?.name,
            value: false,
          },
        ],
        []
      );

      setInterests(interestsArray);
    } catch (error) {
      console.error("Error fetching roles and interests:", error);
    }
  };

  useLayoutEffect(() => {
    getRolesInterests();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterOnClick = () => {
    setOpenPopover(false);

    dispatch(
      filterPartnersFunction({
        profession: formData?.profession,
        city: formData?.city,
        skills: [formData?.skill],
        interests: formData?.interests,
      })
    );
  };

  const handleClearFilter = () => {
    setOpenPopover(false);
    getPartnersListing();
    setFormData(filtersData);
  };
  return (
    <div className="">
      <div className="bg-bggrey pt-4 sticky top-0 z-50 rounded-b-xl">
        <div className="bg-white rounded-xl">
          <div className="flex justify-between items-center pr-5">
            <div className="font-nunito vertical-center gap-4 p-2 py-3 pl-5">
              <PartnersIcon width={30} height={30} />
              <h3 className="text-xl">Partners</h3>
            </div>
            <CustomPopover
              open={openPopover}
              setOpen={setOpenPopover}
              placement="bottom-end"
              trigger={
                <div className="cursor-pointer">
                  <Filter />
                </div>
              }
            >
              <div className="gap-3 w-80 flex flex-col items-center bg-white p-2 rounded-md">
                <div className="flex flex-col gap-3 w-full border-b border-gray-300 pb-2">
                  <div className="vertical-center justify-between">
                    <p className="font-nunito font-semibold text-sm">
                      Filter Options
                    </p>
                    <p onClick={handleClearFilter} className="font-nunito cursor-pointer font-semibold text-sm text-secondary">
                      Clear
                    </p>
                  </div>
                  <div className="flex flex-col gap-4">
                    <SelectDropdown
                      options={statesList}
                      label="Select State"
                      name="state"
                      value={formData.state}
                      onChange={handleStateChange}
                    />
                    <SelectDropdown
                      options={cityList}
                      disabled={!formData.state}
                      loading={cityLoading}
                      label="Select City"
                      name="city"
                      value={formData.city}
                      onChange={handleCityChange}
                      description={
                        cityLoading
                          ? "Loading..."
                          : !formData.state
                          ? "Please Select State First"
                          : ""
                      }
                    />

                    {/* Intrest  */}
                    <div>
                      <p className="font-normal text-sm pb-1">Interests </p>
                      <div className="flex flex-wrap gap-2">
                        {interests?.map((item) => {
                          const isChecked = formData?.interests.includes(
                            item?.name
                          );
                          return (
                            <SignupInterestCheckbox
                              key={item?.id}
                              name={item?.name}
                              value={isChecked}
                              onChange={() => handleInterestsChange(item?.name)}
                            >
                              {item?.label}
                            </SignupInterestCheckbox>
                          );
                        })}
                      </div>
                    </div>

                    {/* profession  */}
                    <div>
                      <p className="font-normal text-sm pb-1">Profession </p>
                      <TextInput
                        label="Enter a profession"
                        type="text"
                        name="profession"
                        value={formData?.profession}
                        onChange={onChange}
                      />
                    </div>

                    {/* Skill  */}
                    <div>
                      <p className="font-normal text-sm pb-1">Skill </p>
                      <TextInput
                        label="Enter a Skill"
                        type="text"
                        name="skill"
                        value={formData?.skill}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <p
                  className="text-sm pb-2 text-secondary font-medium cursor-pointer hover:text-secondary-dark hover:underline transition-colors duration-200 ease-in-out"
                  onClick={filterOnClick}
                >
                  Apply Filters
                </p>
              </div>
            </CustomPopover>
          </div>
          <div className="px-2">
            <Divider />
          </div>
          <div className="flex justify-between items-center pr-3">
            <div className="px-5 py-3 vertical-center flex-wrap gap-2">
              <Link to="/partners/suggestions">
                <Chip
                  cross={false}
                  variant={
                    location?.pathname === "/partners/suggestions"
                      ? "filled"
                      : "outlined"
                  }
                  text="Suggestions"
                />
              </Link>
              <Link to="/partners/matched">
                <Chip
                  cross={false}
                  variant={
                    location?.pathname === "/partners/matched"
                      ? "filled"
                      : "outlined"
                  }
                  text="Matched"
                />
              </Link>
              <Link to="/partners/prioritized">
                <Chip
                  cross={false}
                  variant={
                    location?.pathname === "/partners/prioritized"
                      ? "filled"
                      : "outlined"
                  }
                  text="Prioritized"
                />
              </Link>
              <Link to="/partners/nearest">
                <Chip
                  cross={false}
                  variant={
                    location?.pathname === "/partners/nearest"
                      ? "filled"
                      : "outlined"
                  }
                  text="Nearest"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Partner;
