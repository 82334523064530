import * as React from 'react';
export const SkillsIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.5 6.5V9.5C13.5 10.9587 12.9205 12.3576 11.8891 13.3891C10.8576 14.4205 9.45869 15 8 15C6.54131 15 5.14236 14.4205 4.11091 13.3891C3.07946 12.3576 2.5 10.9587 2.5 9.5V4C2.5 3.73478 2.60536 3.48043 2.79289 3.29289C2.98043 3.10536 3.23478 3 3.5 3C3.76522 3 4.01957 3.10536 4.20711 3.29289C4.39464 3.48043 4.5 3.73478 4.5 4V7.5C4.5 7.63261 4.55268 7.75979 4.64645 7.85355C4.74021 7.94732 4.86739 8 5 8C5.13261 8 5.25979 7.94732 5.35355 7.85355C5.44732 7.75979 5.5 7.63261 5.5 7.5V2C5.5 1.73478 5.60536 1.48043 5.79289 1.29289C5.98043 1.10536 6.23478 1 6.5 1C6.76522 1 7.01957 1.10536 7.20711 1.29289C7.39464 1.48043 7.5 1.73478 7.5 2V7C7.5 7.13261 7.55268 7.25979 7.64645 7.35355C7.74021 7.44732 7.86739 7.5 8 7.5C8.13261 7.5 8.25979 7.44732 8.35355 7.35355C8.44732 7.25979 8.5 7.13261 8.5 7V3C8.5 2.73478 8.60536 2.48043 8.79289 2.29289C8.98043 2.10536 9.23478 2 9.5 2C9.76522 2 10.0196 2.10536 10.2071 2.29289C10.3946 2.48043 10.5 2.73478 10.5 3V8.04187C9.80137 8.16097 9.16732 8.52316 8.70986 9.06445C8.2524 9.60574 8.00098 10.2913 8 11C8 11.1326 8.05268 11.2598 8.14645 11.3536C8.24021 11.4473 8.36739 11.5 8.5 11.5C8.63261 11.5 8.75979 11.4473 8.85355 11.3536C8.94732 11.2598 9 11.1326 9 11C9 10.4696 9.21071 9.96086 9.58579 9.58579C9.96086 9.21071 10.4696 9 11 9C11.1326 9 11.2598 8.94732 11.3536 8.85355C11.4473 8.75979 11.5 8.63261 11.5 8.5V6.5C11.5 6.23478 11.6054 5.98043 11.7929 5.79289C11.9804 5.60536 12.2348 5.5 12.5 5.5C12.7652 5.5 13.0196 5.60536 13.2071 5.79289C13.3946 5.98043 13.5 6.23478 13.5 6.5Z'
      fill={color || 'currentColor'}
    />
  </svg>
);
