import React, { useRef } from "react";

export const PartnersProfileButton = ({
  icon,
  label,
  value,
  onChange,
  selectedValue,
  disabled,
}) => {
  const radioInputRef = useRef(null);
  const handleClick = (event) => {
    event.target.value = null;
    radioInputRef.current.click();
  };

  return (
    <>
      <div
        onClick={disabled ? null : handleClick}
        className={`flex flex-col items-center gap-2 select-none ${disabled ? "cursor-not-allowed" : "cursor-pointer "}`}
      >
        <div
          className={`center size-16 border-[1.5px] ${selectedValue === value ? "border-primary" : ""
            } rounded-xl ${disabled ? "!text-lightgrey" : ""} bg-bggrey/50`}
        >
          {icon}
        </div>
        <span className="font-nunito font-medium text-sm">{label}</span>
      </div>

      <input
        type="radio"
        className="hidden"
        ref={radioInputRef}
        value={value}
        checked={selectedValue === value}
        onChange={onChange}
      />
    </>
  );
};
