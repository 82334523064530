import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  TextInput,
  CustomModal,
  AddMore,
  showToast,
  Spinner,
  Skeleton,
} from "../../../../components/shared";
import { DeleteIcon, LongArrow } from "../../../../assets/icons";
import {
  deleteCertificatesFunction,
  getCertificatesFunction,
  updateCertificatesFunction,
} from "../../../../redux/reducers/userReducers/certificationReducer/certificationReducer";
import { isEmpty } from "../../../../utils/validations";
import { formatDate } from "../../../../utils/utils";

export const Certifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { certifications, loading } = useSelector(
    (state) => state?.certifications
  );
  const [openCerificationsModal, setOpenCerificationsModal] = useState(false);
  const [certification, setCertification] = useState({
    certificationName: "",
    link: "",
    date: "",
    organization: "",
    proofUpload: null,
    certificationNumber:''
  });

  // Get Certifications
  const getCertifications = async () => {
    await dispatch(getCertificatesFunction());
  };

  useLayoutEffect(() => {
    getCertifications();
  }, []);

  // Handle Certification Change
  const handleCertificationChange = (event) => {
    const { name, value } = event.target;
    setCertification((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Add Certifications
  const addCertification = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", certification.certificationName);
    formData.append("certificate_url", certification.link);
    formData.append("date", certification.date);
    formData.append("organization", certification.organization);

    if (certification.proofUpload) {
      formData.append("file_upload", certification.proofUpload);
    }
    if (certification?.certificationNumber) {
      formData.append("cert_num", certification.certificationNumber);
    }

    try {
      const data = await dispatch(updateCertificatesFunction(formData));

      // Check if the request was fulfilled or rejected
      if (data?.meta?.requestStatus === "fulfilled") {
        setOpenCerificationsModal(false);
        showToast("Certificate Added Successfully", "success", {
          position: "top-center",
        });
        getCertifications(); // Fetch updated certifications list
        setCertification({
          certificationName: "",
          link: "",
          date: "",
          organization: "",
          proofUpload: null,
        }); // Reset certification state
      } else {
        const errorMessages = data?.payload || "Something went wrong";
        showToast(errorMessages, "error", { position: "top-center" });
      }
    } catch (error) {
      showToast("An unexpected error occurred", "error", {
        position: "top-center",
      });
    }
  };

  // Delete Certifications
  const handleCloseModal = () => {
    setOpenCerificationsModal(false);
  };

  // Delete Certifications
  const deleteCertifications = async (id) => {
    const data = await dispatch(
      deleteCertificatesFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Certificate Deleted Successfully", "success", {
        position: "top-center",
      });
      getCertifications();
    }
  };

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCertification((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };
  {
    console.log(certifications);
  }
  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Certifications</h3>
        </div>
        <AddMore
          size={"size-8"}
          onClick={() => setOpenCerificationsModal(true)}
        />
      </div>

      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col gap-6 mt-4">
          {loading ? (
            <>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className="border-b border-grey/30 last:border-0 pb-5 flex flex-col gap-2"
                >
                  <Skeleton key={index} className="h-4 w-40 rounded-full" />
                  <Skeleton className="h-3 w-24 rounded-full" />
                  <Skeleton className="h-3 w-32 rounded-full" />
                </div>
              ))}
            </>
          ) : (
            <>
              {certifications?.length > 0 ? (
                <>
                  {certifications?.map((certificate, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="vertical-center justify-between">
                          <p>{certificate?.name}</p>
                          <DeleteIcon
                            className="cursor-pointer"
                            onClick={() =>
                              deleteCertifications(certificate?.id)
                            }
                          />
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{certificate?.organization}</p>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(certificate?.date)}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold mb-2">
                  No Certifications Found
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={"Add Certifications"}
        open={openCerificationsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addCertification} className="space-y-4">
          <div className="flex flex-col gap-5">
            <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
              <p className="font-bold text-sm text-primary">
                Personal Certifications
              </p>
            </div>

            <div className="vertical-center gap-2">
              <h2 className="text-lg font-bold text-gray-800">
                Certifications
              </h2>
              {loading ? <Spinner /> : null}
            </div>

            <div className={`space-y-4`}>
              <TextInput
                label="Certification Name"
                name="certificationName"
                value={certification?.certificationName}
                onChange={handleCertificationChange}
                required={true}
              />

              <TextInput
                label="Certification Number"
                name="certificationNumber"
                type={'number'}
                value={certification?.certificationNumber}
                onChange={handleCertificationChange}
              />

              <TextInput
                label="Link"
                name="link"
                value={certification.link}
                onChange={handleCertificationChange}
              />

              <TextInput
                label="Date"
                name="date"
                type="date"
                value={certification?.date}
                onChange={handleCertificationChange}
                max={new Date().toISOString().split("T")[0]}
                required={true}
              />

              <TextInput
                label="Organization"
                name="organization"
                value={certification.organization}
                onChange={handleCertificationChange}
                required={true}
              />

              <div>
                <label
                  htmlFor="proofUpload"
                  className="block text-[14px] font-medium text-mediumgray"
                >
                  Proof of Certification
                </label>
                <input
                  type="file"
                  id="proofUpload"
                  name="proofUpload"
                  onChange={handleFileChange}
                  className="mt-1 block w-fit text-sm text-mediumgray"
                />
              </div>
            </div>
            <AddMore
              text={"Add Certificate"}
              onClick={addCertification}
              disabled={isEmpty({
                name: certification?.certificationName,
                date: certification?.date,
                organization: certification?.organization,
              })}
            />
          </div>
        </form>
      </CustomModal>
    </div>
  );
};
