// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for skills
const initialState = {
  skills: [],
  loading: false,
  error: null,
};

// Get Skills API Function
export const getSkillsFunction = createAsyncThunk(
  'user/getSkills',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.Skills);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Skills API Function
export const updateSkillsFunction = createAsyncThunk(
  'user/udateSkills',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.Skills, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Skills API Function
export const deleteSkillsFunction = createAsyncThunk(
  'user/deleteSkills',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(endPoints.Skills + values.id, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const skillsSlice = createSlice({
  name: 'user/skills',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    // Get Skills Reducers
    builder
      .addCase(getSkillsFunction.pending, (state) => {
        state.loading = true;
        state.skills = null;
        state.error = null;
      })
      .addCase(getSkillsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.skills = action.payload;
        state.error = null;
      })
      .addCase(getSkillsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.skills = null;
      })
      .addCase(updateSkillsFunction.pending, (state) => {
        state.loading = true;
        state.skills = null;
        state.error = null;
      })
      .addCase(updateSkillsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.skills = action.payload;
      })
      .addCase(updateSkillsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.skills = null;
      })
      .addCase(deleteSkillsFunction.pending, (state) => {
        state.loading = true;
        state.skills = null;
        state.error = null;
      })
      .addCase(deleteSkillsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.skills = action.payload;
      })
      .addCase(deleteSkillsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.skills = null;
      });
  },
});

export default skillsSlice.reducer;
