// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../network/https';
import { endPoints } from '../../network/constant';

// Initial state for Roles & Interests
const initialState = {
  rolesInterests: null,
  loading: false,
  error: null,
}

// Roles & Interests API Function
export const rolesAndInterestsFunction = createAsyncThunk(
  'rolesInterests',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.RolesInterests, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Reducers
export const rolesInterestsSlice = createSlice({
  name: 'rolesInterests',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(rolesAndInterestsFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.rolesInterests = null;
      })
      .addCase(rolesAndInterestsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesInterests = action.payload;
        state.error = null;
      })
      .addCase(rolesAndInterestsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.rolesInterests = null;
      })
  },
});

export default rolesInterestsSlice.reducer;
