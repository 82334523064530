import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  Chip,
  showToast,
  Skeleton,
  Spinner,
  TextInput,
} from "../../../../components/shared";
import { CustomModal } from "../../../../components/shared";
import { AddMore } from "../../../../components/shared/AddMore";
import {
  deleteSkillsFunction,
  getSkillsFunction,
  updateSkillsFunction,
} from "../../../../redux/reducers/userReducers/skillsReducer/skillsReducer";
import { capitalize } from "../../../../utils/utils";
import { LongArrow } from "../../../../assets/icons";

export const Skills = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.skills);
  const [openSkillsModal, setOpenSkillsModal] = useState(false);
  const [skill, setSkill] = useState("");
  const [skillsList, setSkillsList] = useState([]);

  const handleCloseModal = () => {
    setOpenSkillsModal(false);
    setSkill("");
  };

  const getSkills = async () => {
    const data = await dispatch(getSkillsFunction());
    setSkillsList(data?.payload);
  };

  const deleteSkills = async (id) => {
    await dispatch(
      deleteSkillsFunction({
        id: id,
      })
    );
    getSkills();
  };

  useLayoutEffect(() => {
    getSkills();
  }, []);

  const addSkill = async () => {
    const checkExistingSkills = skillsList?.some(
      (skillName) => skillName?.name?.toLowerCase() === skill?.toLowerCase()
    );

    if (checkExistingSkills) {
      showToast("Skill is already in the list", "error", {
        position: "top-center",
      });
      return;
    }

    const data = await dispatch(
      updateSkillsFunction({
        name: skill,
      })
    );

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenSkillsModal(false);
      showToast("Skill Added Successfully", "success", {
        position: "top-center",
      });
      setSkill("");
      getSkills();
    }
  };

  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Skills</h3>
        </div>
        <AddMore size={"size-8"} onClick={() => setOpenSkillsModal(true)} />
      </div>
      <div className="font-nunito mt-4 bg-white rounded-lg p-3">
        {loading ? (
          <div className="mt-6 vertical-center gap-3 flex-wrap">
            {Array.from({ length: 10 }, (_, index) => (
              <Skeleton key={index} className="w-32 h-10 rounded-full" />
            ))}
          </div>
        ) : (
          <div className="my-2 vertical-center gap-3 flex-wrap w-full">
            {skillsList?.length > 0 ? (
              skillsList?.map((item, index) => (
                <Chip
                  key={index}
                  variant={"filled"}
                  text={capitalize(item?.name)}
                  onClick={() => deleteSkills(item?.id)}
                />
              ))
            ) : (
              <p className="font-semibold mx-auto">No Skills Added</p>
            )}
          </div>
        )}
      </div>

      <CustomModal
        title={"Skills"}
        open={openSkillsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
          <p className="font-bold text-sm text-primary">Extra Skills</p>
          {/* <p className="font-bold text-sm text-darkgray">*Optional</p> */}
        </div>

        <div className="vertical-center gap-3">
          <p className="font-nunito text-lg font-bold">
            Professional Abilities
          </p>
          {loading ? <Spinner /> : null}
        </div>
        <form
          className="flex flex-col gap-5 mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            addSkill();
          }}
        >
          <TextInput
            label="Skill"
            type="text"
            name="skill"
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            required={true}
          />

          <AddMore
            text={"Add Skill"}
            onClick={addSkill}
            disabled={loading || !skill?.trim()}
          />
        </form>
      </CustomModal>
    </div>
  );
};
