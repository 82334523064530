import * as React from "react";

export const MailIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 22}
    height={height || 17}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.25 0.5H1.05C0.837827 0.5 0.634344 0.584286 0.484315 0.734315C0.334285 0.884344 0.25 1.08783 0.25 1.3V14.9C0.25 15.3243 0.418571 15.7313 0.718629 16.0314C1.01869 16.3314 1.42565 16.5 1.85 16.5H19.45C19.8743 16.5 20.2813 16.3314 20.5814 16.0314C20.8814 15.7313 21.05 15.3243 21.05 14.9V1.3C21.05 1.08783 20.9657 0.884344 20.8157 0.734315C20.6657 0.584286 20.4622 0.5 20.25 0.5ZM10.65 9.015L3.107 2.1H18.193L10.65 9.015ZM7.721 8.5L1.85 13.881V3.119L7.721 8.5ZM8.905 9.585L10.105 10.69C10.2526 10.8255 10.4456 10.9007 10.646 10.9007C10.8463 10.9007 11.0394 10.8255 11.187 10.69L12.387 9.585L18.187 14.9H3.107L8.905 9.585ZM13.579 8.5L19.45 3.118V13.882L13.579 8.5Z"
      fill="currentColor"
    />
  </svg>
);
