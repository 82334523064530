// Library imports
import React, { useState } from 'react';
import { Avatar, Badge } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Icons imports
import {
  ProfileIcon,
  DotsIcon,
  BellIcon,
  MailIcon
} from '../../../assets/icons';

// Local imports
import { assets } from '../../../assets/images';
import { IconNavigation } from '../IconNavigation';
import { CustomPopover } from '../Popover';
import { userSideMenuRoutes } from '../../../routes/config';
import { capitalize, checkRoute, logoutFn } from '../../../utils/utils';
import { CustomModal } from '../Modal';

export const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.user);
  const [openModal, setOpenModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  return (
    <div>
      <div className='bg-white min-h-[65px] w-full border-b border-mediumgrey/50'>
        <nav className='max-w-[1280px] mx-auto px-6 py-2  pt-3.5 md:pt-2 vertical-center justify-between'>
          <div className='font-nunito flex items-baseline justify-center cursor-pointer' onClick={() => navigate('/home')}>
            <img src={assets.logo} alt='logo' className='size-9' />
          </div>

          {/* Navigation */}
          <div className='hidden gap-8 justify-center items-center md:flex text-grey'>
            {userSideMenuRoutes?.map((menu, index) => {
              return (
                <IconNavigation
                  active={checkRoute(menu?.path)}
                  key={index}
                  icon={menu?.icon}
                  text={menu?.text}
                  onClick={() => navigate(menu?.path)}
                />
              );
            })}
          </div>

          {/* Notification */}
          <div className='vertical-center gap-5 md:gap-8'>
            <Badge
              content=''
              size='sm'
              color='danger'
              shape='circle'
              placement='top-right'
              isInvisible={false}
              classNames={{
                badge: '-translate-y-[3px] translate-x-1',
              }}
              showOutline={false}
            >
              <BellIcon className='cursor-pointer size-5 md:size-6' />
            </Badge>
            <MailIcon className='cursor-pointer size-5 md:size-6' />

            {/* User Info */}
            <CustomPopover
              open={openPopover}
              setOpen={setOpenPopover}
              trigger={
                <div>
                  <div className='hidden md:vertical-center gap-2'>
                    <Avatar
                      src='https://i.pravatar.cc/150?u=a042581f4e29026024d'
                      color='secondary'
                      showFallback
                    />
                  </div>
                  <div className='flex md:hidden items-center gap-2'>
                    <DotsIcon />
                  </div>
                </div>
              }
            >
              <div className='gap-3 w-48 flex flex-col items-center bg-white p-2 rounded-md'>
                <div className='flex flex-col gap-3 w-full border-b border-gray-300 pb-2'>
                  <div className='vertical-center gap-2'>
                    <Avatar
                      src='https://i.pravatar.cc/150?u=a042581f4e29026024d'
                      color='secondary'
                      showFallback
                    />
                    <div className='font-nunito'>
                      <p className='font-semibold text-sm'>{user ? capitalize(user?.first_name) + ' ' + capitalize(user?.last_name) : 'John Doe'}</p>
                      <p className='font-semibold text-[13px] text-darkgrey'>
                        {user ? user?.role : '--'}
                      </p>
                    </div>
                  </div>

                  <IconNavigation
                    active={checkRoute('/profile')}
                    icon={<ProfileIcon width={22} height={22} />}
                    text='Profile'
                    onClick={() => {
                      setOpenPopover(false);
                      navigate('/profile')
                    }}
                    row
                  />
                </div>

                <div className='md:hidden flex flex-col gap-3 w-full border-b border-gray-300 pb-2'>
                  {userSideMenuRoutes?.map((menu, index) => {
                    return (
                      <IconNavigation
                        active={checkRoute(menu?.path)}
                        key={index}
                        icon={menu?.icon}
                        text={menu?.text}
                        onClick={() => {
                          setOpenPopover(false);
                          navigate(menu?.path)
                        }}
                        row
                      />
                    );
                  })}
                </div>

                <p
                  className='font-nunito font-semibold text-sm cursor-pointer'
                  onClick={() => {
                    setOpenPopover(false);
                    setOpenModal(true)
                  }}
                >
                  Log Out
                </p>
              </div>
            </CustomPopover>
          </div>
        </nav>
      </div>

      <div className='bg-bggrey h-[calc(100vh-65px)] overflow-auto'>
        <div className='max-w-[1280px] mx-auto px-2 smd:px-6'>{children}</div>
      </div>

      <CustomModal
        title={'Logout'}
        open={openModal}
        close={() => setOpenModal(false)}
        primaryButtonClick={() => {
          logoutFn();
          navigate('/login');
        }}
        secondaryButtonClick={() => setOpenModal(false)}
        primaryButtonText={"Logout"}
      >
        <div className='font-nunito pb-3 flex justify-between items-center'>
          <p className='font-bold text-sm'>Are you sure you want to logout?</p>
        </div>

      </CustomModal>
    </div>
  );
};