// Library Imports
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// Local Imports
import { useIsAuthenticatedUser } from '../utils/utils';

const PrivateRoutes = () => {
  const location = useLocation();
  const { isAuthenticated, isResetPassword } = useIsAuthenticatedUser();

  if (isAuthenticated) {
    if (isResetPassword && location.pathname !== '/reset-password') {
      return <Navigate to='/reset-password' />;
    } else {
      return <Outlet />;
    }
  } else {
    if (location?.path === '/signup') {
      return <Navigate to='/suggestions' />;
    } else {
      return <Navigate to='/login' />;
    }
  }
}

export default PrivateRoutes