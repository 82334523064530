import React from 'react';

// Local Imports
import { Divider, TextInput } from '../../../../components/shared';
import { AppleIcon, FacebookIcon, GoogleIcon } from '../../../../assets/icons';


const SignupForm = ({ formData, formErrors, onChange, onSubmit, passwordCriteria }) => {
  return (
    <>
      {/* Login Headings */}
      <div className='font-nunito mb-5'>
        <p className='text-2xl font-bold'>Personal Details</p>
        <p className='text-darkgrey text-sm leading-4 mt-1'>Enter the details below to sign up. It only takes a few seconds to complete.</p>
      </div>

      {/* Login Form */}
      <form className='flex flex-col gap-5 mb-2'
        onSubmit={onSubmit}
      >
        <TextInput
          label='First Name'
          type='text'
          name='fname'
          error={formErrors?.fname !== ''}
          errorMsg={formErrors?.fname}
          value={formData?.fname}
          onChange={onChange}
        />
        <TextInput
          label='Last Name'
          type='text'
          name='lname'
          error={formErrors?.lname !== ''}
          errorMsg={formErrors?.lname}
          value={formData?.lname}
          onChange={onChange}
        />
        <TextInput
          label='Email Address'
          type='email'
          name='email'
          error={formErrors?.email !== ''}
          errorMsg={formErrors?.email}
          value={formData?.email}
          onChange={onChange}
        />
        <TextInput
          label='Phone Number'
          type='number'
          name='phone'
          max={14}
          error={formErrors?.phone !== ''}
          errorMsg={formErrors?.phone}
          value={formData?.phone}
          onChange={onChange}
        />
        <TextInput
          label='New Password'
          type='password'
          name='newPassword'
          error={formErrors?.newPassword !== ''}
          errorMsg={formErrors?.newPassword}
          value={formData?.newPassword}
          onChange={onChange}
        />
        <TextInput
          label='Confirm Password'
          type='password'
          name='confirmPassword'
          error={formErrors?.confirmPassword !== ''}
          errorMsg={formErrors?.confirmPassword}
          value={formData?.confirmPassword}
          onChange={onChange}
        />

        <ul className='text-xs select-none ml-1.5 grid grid-cols-1 xs:grid-cols-2 gap-1'>
          <li className='text-darkgrey text-sm vertical-center gap-2'><div className={`list-bullet ${passwordCriteria?.minLength ? "bg-success" : "bg-danger"}`} />Min 8 character</li>
          <li className='text-darkgrey text-sm vertical-center gap-2'><div className={`list-bullet ${passwordCriteria?.hasUppercase ? "bg-success" : "bg-danger"}`} />One uppercase letter</li>
          <li className='text-darkgrey text-sm vertical-center gap-2'><div className={`list-bullet ${passwordCriteria?.hasLowercase ? "bg-success" : "bg-danger"}`} />One lowercase letter</li>
          <li className='text-darkgrey text-sm vertical-center gap-2'><div className={`list-bullet ${passwordCriteria?.hasNumber ? "bg-success" : "bg-danger"}`} />One number</li>
          <li className='text-darkgrey text-sm vertical-center gap-2'><div className={`list-bullet ${passwordCriteria?.hasSpecialChar ? "bg-success" : "bg-danger"}`} />One special character</li>
        </ul>

        {/* <Divider text={'OR'} /> */}

        {/* Signup Social Options */}
        <div className='hidden items-center justify-center gap-5 font-nunito'>
          <button
            className='bg-black text-white social-button'
          >
            <AppleIcon width={28} height={28} />
          </button>
          <button
            className='bg-white border text-black social-button'
          >
            <GoogleIcon width={22} height={22} />
          </button>
          <button
            className='bg-primary text-white social-button'
          >
            <FacebookIcon width={22} height={22} />
          </button>
        </div>
      </form>
    </>
  )
}

export default SignupForm