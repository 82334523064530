import React from 'react'
import { CrossIcon } from '../../../assets/icons'
import { capitalize } from '../../../utils/utils';

export const Chip = ({ text, onClick, cross = true, variant }) => {
  const capitalizedText = text?.split(' ')?.map((item) => capitalize(item))?.join(' ');
  return (
    <span
      className={`inline-flex items-center gap-2 justify-center rounded-full px-4 py-2 select-none ${variant === "filled"
        ? "bg-secondary border-[1.2pt] border-secondary text-white"
        : "bg-none border-[1.2pt] border-darkgrey text-darkgrey"
        }
      ${cross ? "" : "cursor-pointer"}
      `}
    >
      <p className="whitespace-nowrap text-sm font-nunito font-semibold">
        {capitalizedText}
      </p>

      {cross ? (
        <button
          className="rounded-full p-0.5 transition text-white cursor-pointer"
          onClick={onClick}
        >
          <CrossIcon width={16} height={16} />
        </button>
      ) : null}
    </span>
  );
};
