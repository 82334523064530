import * as React from "react";

export const PhoneIcon = ({ width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12.735 20.191a15 15 0 0 1-.92-.447a19 19 0 0 1-4.1-3.12A18 18 0 0 1 3.88 11.42a11.3 11.3 0 0 1-1.022-3.325a5.93 5.93 0 0 1 .37-3.465c.289-.47.637-.9 1.035-1.279a1.8 1.8 0 0 1 1.278-.601c.505.076.962.34 1.278.742c.69.767 1.43 1.457 2.159 2.186c.287.246.466.595.498.972c-.012.317-.134.62-.345.857c-.242.307-.536.588-.817.882a1.54 1.54 0 0 0-.46 1.279a3.7 3.7 0 0 0 .881 1.457c.486.665.971 1.28 1.52 1.931a13.6 13.6 0 0 0 3.463 2.865a1.28 1.28 0 0 0 1.278.153a4 4 0 0 0 1.137-.946c.275-.335.669-.55 1.099-.601c.383.02.744.184 1.01.46c.344.294.638.64.958.959c.319.32.575.55.843.844q.482.425.907.908c.22.284.324.64.294.997a2.1 2.1 0 0 1-.703 1.087a4.78 4.78 0 0 1-3.756 1.458a10.7 10.7 0 0 1-4.05-1.049Z"
    />
  </svg>
);
