import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { UnverifiedIcon, VerifiedIcon } from '../../../../assets/icons'
import { CustomModal, CustomTooltip, SelectDropdown, showToast, Skeleton } from '../../../../components/shared'
import { userVerificationFunction } from '../../../../redux/reducers/adminReducers/userVerificationReducer';
import { verificationOptions } from '../../../../utils/mockupData';

const Licences = ({ loading, profileData, getUserDetails }) => {
  const { licenses } = profileData;
  const dispatch = useDispatch();
  const { loading: verificationLoading } = useSelector((state) => state?.verification);
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null
  });
  const [verification, setVerification] = useState('');

  const verifyLicence = async () => {
    const data = await dispatch(userVerificationFunction({
      userId: profileData?.id,
      id: openModal?.data?.id,
      section: 'licenses',
      verification: verification
    }))
    setOpenModal(false)
    if (data?.meta?.requestStatus === 'rejected') {
      const errorMessages = data?.payload ? data?.payload : 'Something went wrong';
      showToast(errorMessages, 'error', { position: 'top-center' });
      return false;
    }
    if (data?.meta?.requestStatus === 'fulfilled') {
      getUserDetails();
    }
  }
  return (
    <>
      <div>
        {loading ? (
          <div className='grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6'>
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className='w-full h-24 rounded-2xl' />
            ))}
          </div>
        ) : (
          <div className='grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6'>
            {licenses?.map((license, index) => {
              return (
                <div
                  key={index}
                  className='bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 cursor-pointer w-full hover:shadow-md min-h-12'
                  onClick={() => {
                    setVerification(license?.verification)
                    setOpenModal({ open: true, data: license })
                  }}
                >
                  <div className='vertical-center justify-between'>
                    <p className='text-md font-semibold'>{license?.name}</p>
                    <CustomTooltip content={license?.verification === 'VERIFIED' ? 'Verified' : 'Unverified. Click to verify'}>
                      {license?.verification === "VERIFIED" ? (
                        <VerifiedIcon className='text-success size-5' />
                      ) : (
                        <UnverifiedIcon className='text-gold size-5' />
                      )}
                    </CustomTooltip>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>

      <CustomModal
        title={'Verify Licence'}
        open={openModal?.open}
        close={() => setOpenModal({
          open: false,
          data: null
        })}
        primaryButtonClick={verifyLicence}
        secondaryButtonClick={() => setOpenModal({
          open: false,
          data: null
        })}
        primaryButtonText={"Update"}
        primaryButtonLoading={verificationLoading}
      >
        <div className='font-nunito pb-3'>
          <p className='text-sm font-semibold mb-4'>Please update status of this user licence</p>
          <SelectDropdown
            options={verificationOptions}
            loading={true}
            label='Select an option'
            name='verification'
            value={verification}
            onChange={(e) => setVerification(e.target.value)}
          />
        </div>

      </CustomModal>
    </>
  )
}

export default Licences