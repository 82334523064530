// Library Imports
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Local Imports 
import { useIsAuthenticatedUser } from '../utils/utils';

const PublicRoutes = () => {
  const location = useLocation();
  const user = useSelector((state) => state?.user?.user);
  const { isAuthenticated, isResetPassword } = useIsAuthenticatedUser();

  if (isAuthenticated) {
    if (isResetPassword) {
      return <Navigate to='/reset-password' />;
    } else if (location?.pathname === '/signup') {
      return <Navigate to='/suggestions' />;
    } else {
      if (user?.all_user_permissions?.includes('superadmin')) {
        return <Navigate to='/users' />;
      }
      return <Navigate to='/home' />;
    }
  } else {
    return <Outlet />;
  }
};

export default PublicRoutes;