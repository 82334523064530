import React from "react";
import { Checkbox, cn } from "@nextui-org/react";

export const CustomCheckbox = ({ label, checked, onChange }) => {
  return (
    <Checkbox
      classNames={{
        base: "w-fit",
        wrapper:
          "group-data-[selected=true]:before:bg-secondary group-data-[selected=true]:after:bg-secondary after:rounded-md before:rounded-md",
        icon: "text-white",
      }}
      isSelected={checked}
      onValueChange={onChange}
    >
      {label}
    </Checkbox>
  );
};
