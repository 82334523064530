import React from 'react';
import { Avatar } from '@nextui-org/react';

// Local Imports
import { ArrowIcon } from '../../../assets/icons';
import { capitalize } from '../../../utils/utils';

export const PartnerCard = ({
  src,
  name,
  designation,
  address,
  onClick
}) => {
  return (
    <div
      className='border rounded-3xl bg-white hover:shadow-md transition p-3 vertical-center gap-3 group cursor-pointer select-none'
      onClick={onClick}
    >
      <Avatar
        showFallback
        size="xl"
        color='secondary'
        className="!size-16"
        classNames={{
          icon: "text-white",
        }}
        src={src}
      />
      <div className='font-nunito'>
        <p className='text-sm font-semibold'>{name?.split(" ")?.map((word) => capitalize(word)).join(" ") || '--'}</p>
        <p className='text-[13px] text-darkgrey'>{designation?.split(" ")?.map((word) => capitalize(word)).join(" ") || '-'}</p>
        <p className='text-xs text-grey'>{address?.split(" ")?.map((word) => capitalize(word)).join(" ") || '-'}</p>
      </div>

      <div className='ml-auto mr-3 group-hover:translate-x-1 transition text-gold'>
        <ArrowIcon />
      </div>
    </div>
  )
};