// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for certifications
const initialState = {
  certifications: [],
  loading: false,
  error: null,
};

// Get Certifications API Function
export const getCertificatesFunction = createAsyncThunk(
  'user/getCertificates',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints?.Certifications);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Certifications API Function
export const updateCertificatesFunction = createAsyncThunk(
  'user/udateCertificates',
  async (values, { rejectWithValue }) => {
    try {
      // Send FormData directly to the server for file upload handling
      const { data } = await axios.post(endPoints.Certifications, values);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);


// Post Certifications API Function
export const deleteCertificatesFunction = createAsyncThunk(
  'user/deleteCertificates',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(endPoints.Certifications + values.id, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const certificationsSlice = createSlice({
  name: 'user/certifications',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Get Certifications Reducers
    builder
      .addCase(getCertificatesFunction.pending, (state) => {
        state.loading = true;
        state.certifications = null;
        state.error = null;
      })
      .addCase(getCertificatesFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.certifications = action.payload;
        state.error = null;
      })
      .addCase(getCertificatesFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.certifications = null;
      })
      .addCase(updateCertificatesFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCertificatesFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateCertificatesFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteCertificatesFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCertificatesFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteCertificatesFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default certificationsSlice.reducer;
