import React from 'react'
import { Chip, Skeleton } from '../../../../components/shared'
import { capitalize } from '../../../../utils/utils'

export const Skills = ({ loading, skills }) => {
  return (
    <div>
      {loading ? (
        <div className='grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6'>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} className='w-full h-24 rounded-2xl' />
          ))}
        </div>
      ) : (
        <div className='vertical-center flex-wrap gap-3 mt-6'>
          {skills?.map((skill, index) => {
            return (
              <Chip
                key={index}
                variant={'filled'}
                text={capitalize(skill?.name)}
                cross={false}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
