import { RoundedCheckIcon } from "../../../assets/icons";


export const SignupInterestCheckbox = ({
  value,
  onChange,
  id,
  name,
  children,
}) => {

  return (
    <>
      <input
        type="checkbox"
        className="hidden peer"
        name={name}
        id={id}
        checked={value}
        onChange={onChange}
      />
      <label
        htmlFor={id}
        onClick={onChange}
        className={`cursor-pointer w-fit px-2 py-1.5 rounded-[0.8rem] border ${value ? 'border-gold bg-gold text-white' : 'border-mediumgrey bg-transparent text-mediumgrey'} text-sm vertical-center gap-1.5 font-nunito select-none`}>
        <RoundedCheckIcon color={value ? 'white' : '#A6A6A7'} />
        {children}
      </label>
    </>
  );
}