import React from 'react'

// Local Imports
import { LongArrow } from '../../../assets/icons'

export const RedirectLink = ({ text, onClick }) => {
  return (
    <div
      className='vertical-center gap-2 text-sm text-gold group cursor-pointer'
      onClick={onClick}
    >
      <p className='font-nunito font-bold'>{text}</p>
      <LongArrow className='group-hover:!translate-x-1 transition' />
    </div>
  )
}

export default RedirectLink