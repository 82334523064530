import * as React from 'react';
export const EditProfileIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || 12}
    height={height || 12}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.9385 11.76C11.8979 11.833 11.8396 11.8936 11.7695 11.9357C11.6993 11.9779 11.6197 12 11.5386 12H0.461091C0.380109 11.9999 0.300575 11.9777 0.230475 11.9355C0.160374 11.8934 0.102174 11.8328 0.0617195 11.7598C0.0212648 11.6869 -2.07751e-05 11.6041 1.52153e-08 11.5199C2.08056e-05 11.4357 0.0213472 11.353 0.0618379 11.2801C0.94054 9.70051 2.29465 8.56787 3.87493 8.03094C3.09325 7.54708 2.48594 6.80979 2.14625 5.9323C1.80657 5.05481 1.75329 4.08564 1.9946 3.17361C2.23592 2.26159 2.75848 1.45715 3.48204 0.883827C4.2056 0.310504 5.09015 0 5.99986 0C6.90956 0 7.79411 0.310504 8.51767 0.883827C9.24123 1.45715 9.7638 2.26159 10.0051 3.17361C10.2464 4.08564 10.1931 5.05481 9.85346 5.9323C9.51377 6.80979 8.90646 7.54708 8.12478 8.03094C9.70506 8.56787 11.0592 9.70051 11.9379 11.2801C11.9785 11.353 11.9999 11.4357 12 11.52C12.0001 11.6042 11.9789 11.687 11.9385 11.76Z'
      fill={color || 'currentColor'}
    />
  </svg>
);
