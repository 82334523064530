import React from 'react';

// Local Imports
import { assets } from '../../../assets/images';

// Background Radiant
const styles = {
  background: "linear-gradient(180deg, rgba(30, 0, 159, 0.9) 0%, rgba(146, 131, 208, 0.9) 51%, rgba(255, 255, 255, 0.9) 81%, rgba(255, 255, 255, 1) 100%)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "0 0, 50px 50px",
};

export const AuthLayout = ({ children }) => {
  return (
    <div style={{ backgroundImage: `url(${assets.background})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "top center", }} className='w-full h-screen overflow-hidden'>
      <div style={styles} className='w-full h-full'>
        {children}
      </div>
    </div>
  )
};