import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  showToast,
  Skeleton,
  Spinner,
  TextInput,
} from "../../../../components/shared";
import { CustomModal } from "../../../../components/shared";
import { DeleteIcon, LongArrow } from "../../../../assets/icons";
import { AddMore } from "../../../../components/shared/AddMore";
import {
  deleteLicensesFunction,
  getLicensesFunction,
  updateLicensesFunction,
} from "../../../../redux/reducers/userReducers/licensesReducer/licensesReducer";
import { capitalize, formatDate } from "../../../../utils/utils";
import { isEmpty } from "../../../../utils/validations";

export const Licenses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.licenses);
  const [openLicencesModal, setOpenLicencesModal] = useState(false);
  const [license, setLicense] = useState({
    licenseName: "",
    licenseNumber:'',
    link: "",
    date: "",
    organization: "",
    proofUpload: null,
  });
  const [licensesList, setLicensesList] = useState([]);

  const handleCloseModal = () => {
    setOpenLicencesModal(false);
    setLicense({
      licenseName: "",
      link: "",
      date: "",
      organization: "",
      proofUpload: null,
    });
  };

  const getLicenses = async () => {
    const data = await dispatch(getLicensesFunction());
    setLicensesList(data?.payload);
  };

  const deleteLicenses = async (id) => {
    const data = await dispatch(
      deleteLicensesFunction({
        id: id,
      })
    );
    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("License Deleted Successfully", "success", {
        position: "top-center",
      });
      setLicense("");
      getLicenses();
    }
    if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join())?.join("\n"),
        {
          position: "top-center",
        }
      );
    }
  };

  useLayoutEffect(() => {
    getLicenses();
  }, []);

  const addLicense = async () => {
    const formData = new FormData();
    formData.append("name", license?.licenseName);
    formData.append("license_url", license?.link);
    formData.append("date", license?.date);
    formData.append("organization", license?.organization);
    if (license?.proofUpload) {
      formData.append("file_upload", license.proofUpload);
    }
    if (license?.licenseNumber) {
      formData.append("license_num", license.licenseNumber);
    }
    const data = await dispatch(updateLicensesFunction(formData));
    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenLicencesModal(false);
      showToast("License Added Successfully", "success", {
        position: "top-center",
      });
      setLicense("");
      getLicenses();
    }
    if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join())?.join("\n"),
        {
          position: "top-center",
        }
      );
    }
  };

  // Handle field changes
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setLicense((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle file change for proofUpload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setLicense((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Licenses</h3>
        </div>
        <AddMore size={"size-8"} onClick={() => setOpenLicencesModal(true)} />
      </div>
      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col gap-6 pt-4">
          {loading ? (
            <>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className="border-b border-grey/30 last:border-0 pb-5 flex flex-col gap-2"
                >
                  <Skeleton key={index} className="h-4 w-40 rounded-full" />
                  <Skeleton className="h-3 w-24 rounded-full" />
                  <Skeleton className="h-3 w-32 rounded-full" />
                </div>
              ))}
            </>
          ) : (
            <>
              {licensesList?.length > 0 ? (
                <>
                  {licensesList?.map((license, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="vertical-center justify-between">
                          <p className="flex-1">{capitalize(license?.name)}</p>
                          <DeleteIcon
                            className="cursor-pointer hover:text-danger"
                            onClick={() => deleteLicenses(license?.id)}
                          />
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{license?.organization}</p>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(license?.date)}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold mb-3">
                  No licenses found
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={"Licenses"}
        open={openLicencesModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
          <p className="font-bold text-sm text-primary">
            Professional Licenses
          </p>
        </div>

        <div className="vertical-center gap-3">
          <p className="font-nunito text-lg font-bold">Name of Licensure </p>
          {loading ? <Spinner /> : null}
        </div>
        <form
          className="flex flex-col gap-5 mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            addLicense();
          }}
        >
          <TextInput
            label="License Name"
            name="licenseName"
            value={license.licenseName}
            onChange={handleFieldChange}
            required={true}
          />

          <TextInput
            label="License Number"
            name="licenseNumber"
            value={license.licenseNumber}
            onChange={handleFieldChange}
          />

          <TextInput
            label="Link"
            name="link"
            value={license.link}
            onChange={handleFieldChange}
          />

          <TextInput
            label="Date"
            name="date"
            type="date"
            value={license.date}
            onChange={handleFieldChange}
            max={new Date().toISOString().split("T")[0]}
            required={true}
          />

          <TextInput
            label="Organization"
            name="organization"
            value={license.organization}
            onChange={handleFieldChange}
            required={true}
          />

          <div className="mb-1">
            <label
              htmlFor="proofUpload"
              className="block text-[14px] font-medium text-mediumgray"
            >
              Proof of License
            </label>
            <input
              type="file"
              id="proofUpload"
              name="proofUpload"
              onChange={handleFileChange}
              className="mt-1 block w-fit text-sm text-mediumgray"
            />
          </div>

          <AddMore
            text={"Add License"}
            onClick={addLicense}
            disabled={
              loading ||
              isEmpty({
                name: license?.licenseName,
                date: license?.date,
                organization: license?.organization,
              })
            }
          />
        </form>
      </CustomModal>
    </div>
  );
};
