import * as React from "react";

export const LongArrow = ({ width, height, ...props }) => (
  <svg
    width={width || 17}
    height={height || 10}
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7951 5.60709L16.7221 5.69209L13.1461 9.42209C12.9699 9.60449 12.731 9.71313 12.4777 9.72602C12.2244 9.73892 11.9757 9.6551 11.7819 9.49154C11.5881 9.32798 11.4637 9.09689 11.4338 8.84505C11.4039 8.59321 11.4709 8.33944 11.6211 8.13509L11.7021 8.03909L13.6571 6.00009L1.00207 6.00009C0.755932 6.00178 0.517817 5.91263 0.333309 5.74971C0.148802 5.58679 0.0308628 5.36154 0.00206947 5.11709V5.00009C0.0021019 4.75516 0.0920258 4.51875 0.254786 4.33572C0.417545 4.15268 0.641817 4.03575 0.885069 4.00709L1.00207 4.00009L13.6581 4.00009L11.7021 1.96109C11.5267 1.77739 11.4277 1.53384 11.4254 1.27987C11.423 1.02589 11.5173 0.780529 11.6893 0.593583C11.8612 0.406637 12.0978 0.292117 12.3511 0.273267C12.6044 0.254418 12.8554 0.332652 13.0531 0.49209L13.1451 0.57709L16.7561 4.34409L16.8261 4.43509L16.8761 4.51609L16.9231 4.61609L16.9661 4.73909C17.0014 4.86427 17.0115 4.99523 16.9957 5.12434C16.9799 5.25345 16.9385 5.37812 16.8741 5.49109L16.7951 5.60709Z"
      fill='currentColor'
    />
  </svg>
);
