// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../network/https';
import { endPoints } from '../../network/constant';

// Initial state for user verification
const initialState = {
  verification: null,
  loading: false,
  error: null,
}

// User Verification API Function
export const userVerificationFunction = createAsyncThunk(
  'userVerification',
  async (values, { rejectWithValue }) => {
    const params = [];
    params.push(`id=${values?.id}`, `section=${values?.section}`);
    try {
      const { data } = await axios.post(endPoints.Verify + values?.userId + '/' + `?${params.join('&')}`, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Reducers
export const userVerificationSlice = createSlice({
  name: 'admin/userVerification',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(userVerificationFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.verification = null;
      })
      .addCase(userVerificationFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.verification = action.payload;
        state.error = null;
      })
      .addCase(userVerificationFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.verification = null;
      })
  },
});

export default userVerificationSlice.reducer;
