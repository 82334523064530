import * as React from 'react';
export const PartnersIcon = (props) => (
  <svg
    width={25}
    height={25}
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.21816 11.6512C9.11816 11.6412 8.99816 11.6412 8.88816 11.6512C6.50816 11.5712 4.61816 9.62125 4.61816 7.22125C4.61816 4.77125 6.59816 2.78125 9.05816 2.78125C11.5082 2.78125 13.4982 4.77125 13.4982 7.22125C13.4882 9.62125 11.5982 11.5712 9.21816 11.6512Z'
      stroke='currentColor'
      strokeWidth={1.63359}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.4679 4.78125C18.4079 4.78125 19.9679 6.35125 19.9679 8.28125C19.9679 10.1713 18.4679 11.7113 16.5979 11.7813C16.5179 11.7713 16.4279 11.7713 16.3379 11.7813'
      stroke='currentColor'
      strokeWidth={1.63359}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.21832 15.3413C1.79832 16.9613 1.79832 19.6012 4.21832 21.2112C6.96832 23.0512 11.4783 23.0512 14.2283 21.2112C16.6483 19.5912 16.6483 16.9513 14.2283 15.3413C11.4883 13.5113 6.97832 13.5113 4.21832 15.3413Z'
      stroke='currentColor'
      strokeWidth={1.63359}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.3975 20.7815C19.1175 20.6315 19.7975 20.3415 20.3575 19.9115C21.9175 18.7415 21.9175 16.8115 20.3575 15.6415C19.8075 15.2215 19.1375 14.9415 18.4275 14.7815'
      stroke='currentColor'
      strokeWidth={1.63359}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
