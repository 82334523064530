// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for educations
const initialState = {
  educations: [],
  loading: false,
  error: null,
};

// Get Education API Function
export const getEducationsFunction = createAsyncThunk(
  'user/getEducations',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints?.Education);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Education API Function
export const updateEducationsFunction = createAsyncThunk(
  'user/udateEducations',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints?.Education, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Education API Function
export const deleteEducationsFunction = createAsyncThunk(
  'user/deleteEducations',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(endPoints?.Education + values.id, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const educationsSlice = createSlice({
  name: 'user/educations',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Get education Reducers
    builder
      .addCase(getEducationsFunction.pending, (state) => {
        state.loading = true;
        state.educations = null;
        state.error = null;
      })
      .addCase(getEducationsFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.educations = action.payload;
        state.error = null;
      })
      .addCase(getEducationsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.educations = null;
      })
      .addCase(updateEducationsFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEducationsFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateEducationsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteEducationsFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteEducationsFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteEducationsFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default educationsSlice.reducer;
