import { getKeyValue, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { Spinner } from "../Spinner";


export const CustomTable = ({
  columns,
  rowsData,
  dataProviders,
  loading
}) => {
  return (
    <Table
      aria-label="table"
      aria-hidden="false"
      isStriped={true}
      isHeaderSticky={true}
      classNames={{
        base: "max-h-[calc(100vh-100px)] overflow-auto",
        table: 'w-fit w-[calc(100vw-280px)] overflow-auto',
        wrapper: 'p-0',
        thead: 'bg-lightgrey text-sm font-nunito text-darkgrey overflow-hidden [&>tr:nth-child(even)]:hidden',
        th: 'font-bold !w-[2000px]',
        tr: 'even:bg-lightgrey py-1',
        td: 'py-3 font-nunito text-sm text-darkgrey'
      }}
    >
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn key={column?.name}>{column?.title}</TableColumn>
        )}
      </TableHeader>

      <TableBody
        emptyContent={'No data found'}
        isLoading={loading}
        loadingContent={<Spinner className={"text-secondary size-10"} />}
      >
        {rowsData?.map((row) =>
          <TableRow key={row?.id}>
            {(columnKey) => {
              const dataProvider = dataProviders?.find(
                (provider) => provider.columnName === columnKey
              );
              if (dataProvider) {
                return <TableCell aria-hidden="false">{dataProvider.component({ row })}</TableCell>
              } else {
                return <TableCell aria-hidden="false">{getKeyValue(row, columnKey)}</TableCell>
              }
            }}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};