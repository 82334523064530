import React, { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local Imports
import { SelectDropdown, TextInput } from "../../../../components/shared";
import { citiesFunction } from "../../../../redux/reducers/authReducers/statesReducer";

const Locations = ({
  formData,
  setFormData,
  onChange,
  onSubmit,
  statesList,
  zipcodeError,
}) => {
  const dispatch = useDispatch();
  const { cityLoading } = useSelector((state) => state?.states);
  const [currentCityList, setCurrentCityList] = useState([]);
  const [operatedCityList, setOperatedCityList] = useState([]);

  const handleOnChange = (event) => {
    const { value, name } = event.target;

    setFormData((prev) => ({
      ...prev,
      locations: {
        ...prev.locations,
        [name]: value,
      },
    }));
  };

  // Get Cities Listing
  const getCities = async (from) => {
    if (from === "current") {
      const citiesList = await dispatch(
        citiesFunction({ id: formData?.currentState })
      );
      setCurrentCityList(citiesList?.payload);
    }
    if (from === "operated") {
      const citiesList = await dispatch(
        citiesFunction({ id: formData?.operatedState })
      );
      setOperatedCityList(citiesList?.payload);
    }
  };

  useLayoutEffect(() => {
    if (!formData?.currentState) return;
    getCities("current");
  }, [formData?.currentState]);

  useLayoutEffect(() => {
    if (!formData?.operatedState) return;
    getCities("operated");
  }, [formData?.operatedState]);

  return (
    <>
      <div className="font-nunito mb-5 mt-3">
        <p className="text-lg font-bold">Where are you based?</p>
        <p className="text-darkgrey text-sm leading-4 mt-1">
          I am currently living in
        </p>
      </div>

      {/* Form */}
      <form className="flex flex-col gap-5 mb-2" onSubmit={onSubmit}>
        <SelectDropdown
          options={statesList}
          loading={true}
          label="Select State"
          name="currentState"
          value={formData?.currentState}
          onChange={handleOnChange}
        />

        <SelectDropdown
          options={currentCityList}
          disabled={!formData?.currentState}
          loading={true}
          label="Select City"
          name="currentCity"
          value={formData?.currentCity}
          onChange={handleOnChange}
          description={
            cityLoading
              ? "Loading..."
              : !formData?.currentState
              ? "Please Select State First"
              : ""
          }
        />

        <TextInput
          label="Zip Code"
          type="number"
          name="currentZipCode"
          value={formData?.currentZipCode}
          onChange={onChange}
          error={zipcodeError?.currentZipCode ? true : false}
          errorMsg={zipcodeError?.currentZipCode}
        />

        <div className="font-nunito mb-5 mt-3">
          <p className="text-lg font-bold">Choose location to operate from</p>
          <p className="text-darkgrey text-sm leading-4 mt-1">
            I would like to operate from
          </p>
        </div>

        <SelectDropdown
          options={statesList}
          loading={true}
          label="Select State"
          name="operatedState"
          value={formData?.operatedState}
          onChange={handleOnChange}
        />

        <SelectDropdown
          options={operatedCityList}
          disabled={!formData?.operatedState}
          label="Select City"
          name="operatedCity"
          value={formData?.operatedCity}
          onChange={handleOnChange}
          description={
            cityLoading
              ? "Loading..."
              : !formData?.operatedState
              ? "Please Select State First"
              : ""
          }
        />

        <TextInput
          label="Zip Code"
          type="number"
          name="operatedZipCode"
          value={formData?.operatedZipCode}
          onChange={onChange}
          error={zipcodeError?.operatedZipCode ? true : false}
          errorMsg={zipcodeError?.operatedZipCode}
        />
      </form>
    </>
  );
};

export default Locations;
