import * as React from "react";
export const ChatIcon = ({ width, height,color, ...props }) => (
  <svg
    width={width || 17}
    height={height || 17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7324 0.514404C15.7968 0.514404 16.6705 1.34255 16.7169 2.37493L16.7189 2.46142V11.5673C16.7189 12.6106 15.874 13.4669 14.8207 13.5125L14.7324 13.5144H4.33531L1.26418 16.525C1.0611 16.724 0.7186 16.5801 0.71875 16.2957L0.726054 2.46142C0.726054 1.41782 1.56479 0.561815 2.61741 0.516307L2.70561 0.514404H14.7324ZM14.7325 1.70643H2.70565C2.30565 1.70643 1.97516 2.01381 1.9446 2.40279L1.94229 2.46172L1.9358 14.1807L3.83212 12.3223H14.7325C15.1346 12.3223 15.4694 12.0129 15.5004 11.6259L15.5027 11.5673V2.46139C15.5027 2.04752 15.1547 1.70643 14.7325 1.70643ZM10.2453 9.85855C10.2453 9.52937 9.97306 9.26253 9.63722 9.26253H5.17785L5.11928 9.26525C4.81092 9.29413 4.56975 9.54874 4.56975 9.85855C4.56975 10.1877 4.842 10.4546 5.17785 10.4546H9.63722L9.69578 10.4518C10.0042 10.423 10.2453 10.1684 10.2453 9.85855ZM11.8415 6.37921C12.1774 6.37921 12.4496 6.64606 12.4496 6.97523C12.4496 7.28504 12.2085 7.53965 11.9001 7.56853L11.8415 7.57126H5.00044C4.66459 7.57126 4.39234 7.30441 4.39234 6.97523C4.39234 6.66542 4.63351 6.41082 4.94187 6.38194L5.00044 6.37921H11.8415ZM12.4496 4.09049C12.4496 3.76131 12.1774 3.49446 11.8415 3.49446H5.00044L4.94187 3.49719C4.63351 3.52607 4.39234 3.78068 4.39234 4.09049C4.39234 4.41966 4.66459 4.68651 5.00044 4.68651H11.8415L11.9001 4.68378C12.2085 4.6549 12.4496 4.4003 12.4496 4.09049Z"
      fill={color || "currentColor"}
    />
  </svg>
);
