import * as React from 'react';
export const EducationIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.9998 12.9524C11.3448 12.8165 11.679 12.655 11.9998 12.4693V14.9999C11.9998 15.1325 11.9472 15.2597 11.8534 15.3535C11.7596 15.4472 11.6325 15.4999 11.4998 15.4999C11.3672 15.4999 11.2401 15.4472 11.1463 15.3535C11.0525 15.2597 10.9998 15.1325 10.9998 14.9999V12.9524ZM11.7348 7.42554L8.23484 5.55867C8.11807 5.49902 7.98254 5.48764 7.85745 5.52696C7.73236 5.56629 7.62773 5.65318 7.56609 5.76891C7.50446 5.88464 7.49075 6.01996 7.52792 6.1457C7.5651 6.27145 7.65018 6.37755 7.76484 6.44117L10.6873 7.99992L11.7498 7.43367L11.7348 7.42554ZM15.7348 5.55867L8.23484 1.55867C8.1625 1.52015 8.0818 1.5 7.99984 1.5C7.91789 1.5 7.83718 1.52015 7.76484 1.55867L0.264844 5.55867C0.184846 5.6013 0.117944 5.66488 0.0712937 5.7426C0.0246433 5.82033 0 5.90927 0 5.99992C0 6.09057 0.0246433 6.17951 0.0712937 6.25723C0.117944 6.33495 0.184846 6.39853 0.264844 6.44117L1.99984 7.36679V10.393C1.99932 10.6386 2.08971 10.8757 2.25359 11.0587C3.07234 11.9705 4.90672 13.4999 7.99984 13.4999C9.02544 13.5084 10.0434 13.3226 10.9998 12.9524V8.16679L10.6873 7.99992L7.99984 9.43304L2.73922 6.62492L1.56234 5.99992L7.99984 2.56679L14.4373 5.99992L13.2636 6.62492H13.2598L11.7498 7.43367C11.8259 7.47756 11.889 7.5407 11.9329 7.61673C11.9768 7.69276 11.9999 7.779 11.9998 7.86679V12.4693C12.6518 12.0929 13.241 11.617 13.7461 11.0587C13.91 10.8757 14.0004 10.6386 13.9998 10.393V7.36679L15.7348 6.44117C15.8148 6.39853 15.8817 6.33495 15.9284 6.25723C15.975 6.17951 15.9997 6.09057 15.9997 5.99992C15.9997 5.90927 15.975 5.82033 15.9284 5.7426C15.8817 5.66488 15.8148 5.6013 15.7348 5.55867Z'
      fill={color || 'currentColor'}
    />
  </svg>
);
