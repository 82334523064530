import * as React from 'react';
export const AddExperienceIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || '11'}
    height={height || '11'}
    viewBox='0 0 11 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.77404 5.125C6.77404 5.24103 6.73149 5.35231 6.65576 5.43436C6.58002 5.51641 6.4773 5.5625 6.37019 5.5625H4.75481C4.6477 5.5625 4.54498 5.51641 4.46925 5.43436C4.39351 5.35231 4.35096 5.24103 4.35096 5.125C4.35096 5.00897 4.39351 4.89769 4.46925 4.81564C4.54498 4.73359 4.6477 4.6875 4.75481 4.6875H6.37019C6.4773 4.6875 6.58002 4.73359 6.65576 4.81564C6.73149 4.89769 6.77404 5.00897 6.77404 5.125ZM10.8125 2.9375V9.9375C10.8125 10.1696 10.7274 10.3921 10.5759 10.5562C10.4245 10.7203 10.219 10.8125 10.0048 10.8125H1.12019C0.905979 10.8125 0.700539 10.7203 0.549068 10.5562C0.397596 10.3921 0.3125 10.1696 0.3125 9.9375V2.9375C0.3125 2.70544 0.397596 2.48288 0.549068 2.31878C0.700539 2.15469 0.905979 2.0625 1.12019 2.0625H3.13942V1.625C3.13942 1.2769 3.26707 0.943064 3.49427 0.696922C3.72148 0.450781 4.02964 0.3125 4.35096 0.3125H6.77404C7.09536 0.3125 7.40352 0.450781 7.63073 0.696922C7.85793 0.943064 7.98558 1.2769 7.98558 1.625V2.0625H10.0048C10.219 2.0625 10.4245 2.15469 10.5759 2.31878C10.7274 2.48288 10.8125 2.70544 10.8125 2.9375ZM3.94712 2.0625H7.17788V1.625C7.17788 1.50897 7.13534 1.39769 7.0596 1.31564C6.98387 1.23359 6.88115 1.1875 6.77404 1.1875H4.35096C4.24385 1.1875 4.14113 1.23359 4.0654 1.31564C3.98966 1.39769 3.94712 1.50897 3.94712 1.625V2.0625ZM10.0048 5.21305V2.9375H1.12019V5.21305C2.48329 6.01683 4.01056 6.4378 5.5625 6.4375C7.11444 6.4378 8.64172 6.01683 10.0048 5.21305Z'
      fill={color || 'currentColor'}
    />
  </svg>
);
