import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local Imports
import {
  CustomModal,
  CustomTooltip,
  SelectDropdown,
  showToast,
  Skeleton,
} from "../../../../../components/shared";
import { UnverifiedIcon, VerifiedIcon } from "../../../../../assets/icons";
import { userVerificationFunction } from "../../../../../redux/reducers/adminReducers/userVerificationReducer";
import { verificationOptions } from "../../../../../utils/mockupData";

export const Certifications = ({ loading, profileData, getUserDetails }) => {
  const { certifications } = profileData;
  const dispatch = useDispatch();
  const { loading: verificationLoading } = useSelector(
    (state) => state?.verification
  );
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null,
  });
  const [verification, setVerification] = useState("");

  const verifyCertificate = async () => {
    const data = await dispatch(
      userVerificationFunction({
        userId: profileData?.id,
        id: openModal?.data?.id,
        section: "certifications",
        verification: verification,
      })
    );
    setOpenModal(false);
    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }
    if (data?.meta?.requestStatus === "fulfilled") {
      getUserDetails();
    }
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-12 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {certifications?.map((certificate, index) => {
              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 cursor-pointer w-full hover:shadow-md min-h-12"
                  onClick={() => {
                    setVerification(certificate?.verification);
                    setOpenModal({ open: true, data: certificate });
                  }}
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold">{certificate?.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <CustomModal
        title={"Verify Certificate"}
        open={openModal?.open}
        close={() =>
          setOpenModal({
            open: false,
            data: null,
          })
        }
        primaryButtonClick={verifyCertificate}
        secondaryButtonClick={() =>
          setOpenModal({
            open: false,
            data: null,
          })
        }
        primaryButtonText={"Update"}
        primaryButtonLoading={verificationLoading}
      >
        <div className="font-nunito pb-3">
          <p className="text-sm font-semibold mb-4">
            Please update status of this user certificate
          </p>
          <SelectDropdown
            options={verificationOptions}
            loading={true}
            label="Select an option"
            name="verification"
            value={verification}
            onChange={(e) => setVerification(e.target.value)}
          />
        </div>
      </CustomModal>
    </>
  );
};
