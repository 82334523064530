// Library imports
import React, { useEffect } from "react";

// Local Imports
import { TopRatedCard } from "../../../components/shared/TopRatedCard";
import { PartnerCard, RedirectLink } from "../../../components/shared";
import { topRatedFunction } from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const partnersListing = useSelector((state) => state?.partners?.topRatedList);

  useEffect(() => {
    const fetchTopRatedList = async () => {
      const data = await dispatch(topRatedFunction());
    };

    fetchTopRatedList();
  }, [dispatch]);

  const profileData = [
    {
      id: 1,
      title: "Complete your Profile",
      description:
        "You're one step closer to unlocking a personalized experience tailored just for you.",
      progress: 50,
    },
    {
      id: 2,
      title: "Set Up Preferences",
      description:
        "Let us know your preferences to help us tailor the experience just for you.",
      progress: 30,
    },
    {
      id: 3,
      title: "Connect Social Accounts",
      description:
        "Link your social media accounts for easier sharing and personalized recommendations.",
      progress: 20,
    },
    {
      id: 4,
      title: "Add Payment Information",
      description:
        "Securely add your payment details for faster checkouts and exclusive offers.",
      progress: 40,
    },
    {
      id: 5,
      title: "Review & Finish",
      description:
        "Review your details and complete the setup for a seamless experience.",
      progress: 10,
    },
  ];

  const projects = [
    {
      id: 1,
      title: "Plumbing and wiring for complex Alpha in Atlanta",
      progress: 20,
      timeline: "03 August - 28 August 2024",
      assignedBy: "Charloh",
      teamMembers: ["NP", "NP", "NP"],
    },
    {
      id: 2,
      title: "Electrical installation for building X in New York",
      progress: 40,
      timeline: "01 July - 15 July 2024",
      assignedBy: "Alex",
      teamMembers: ["AB", "XY", "LP"],
    },
    {
      id: 3,
      title: "HVAC system installation in Phoenix",
      progress: 60,
      timeline: "10 June - 25 June 2024",
      assignedBy: "John",
      teamMembers: ["JD", "WP", "QW"],
    },
    {
      id: 4,
      title: "Plumbing upgrades for Mall Z in Dallas",
      progress: 80,
      timeline: "01 May - 20 May 2024",
      assignedBy: "Sarah",
      teamMembers: ["SH", "ES", "KS"],
    },
    {
      id: 5,
      title: "Wiring setup for Office Complex Y in LA",
      progress: 30,
      timeline: "15 April - 30 April 2024",
      assignedBy: "Michael",
      teamMembers: ["MH", "RC", "RT"],
    },
    {
      id: 6,
      title: "Installation of electrical panels in Chicago",
      progress: 50,
      timeline: "05 March - 20 March 2024",
      assignedBy: "Emma",
      teamMembers: ["EM", "CV", "DF"],
    },
  ];

  return (
    <>
      {/* Top Rated */}
      <div className="pt-6">
        <div className="bg-white rounded-xl">
          <div className="flex items-center gap-4 p-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-gray-700">Top Rated</h3>
          </div>

          <div className="mx-5 py-3 mb-2 flex flex-wrap gap-1 overflow-x-scroll no-scrollbar">
            <TopRatedCard list={partnersListing} />
          </div>
        </div>
      </div>

      {/* Video Section */}
      <div className="pt-6">
        <div className="bg-white rounded-xl">
          <div className="flex items-center gap-4 p-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-gray-700">
              Tutorial Video
            </h3>
          </div>

          <div className="p-4">
            <div className="w-full h-[400px] bg-gray-200 rounded-lg">
              <iframe
                className="w-full h-full rounded-lg"
                src="https://www.youtube.com/embed/JoKsuFIrpfk"
                title="Featured Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* profile Section */}
      <div className="pt-6">
        <div className="bg-white rounded-xl">
          <div className="max-w-full mx-auto p-4 overflow-x-auto pb-6 no-scrollbar">
            <div className="flex gap-6">
              {profileData.map((profile) => (
                <div
                  key={profile.id}
                  className="bg-white rounded-lg shadow-xl w-[600px] h-[300px] flex flex-col transform transition-transform duration-300 cursor-pointer hover:shadow-2xl"
                >
                  <div className="flex h-[100px] items-center p-6 bg-purple-600 rounded-t-lg">
                    <h1 className="text-base font-semibold text-white">
                      {profile.title}
                    </h1>
                  </div>
                  <div className="px-6 py-4 flex-1">
                    <p className="text-base text-gray-700 mb-4 h-[90px] line-clamp-4 overflow-hidden">
                      {profile.description}
                    </p>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                      <div
                        className="bg-yellow-400 h-2.5 rounded-full"
                        style={{ width: `${profile.progress}%` }}
                      ></div>
                    </div>
                    <p className="text-sm text-gray-500">
                      Complete: {profile.progress}%
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Partners */}
      <div className="pt-6">
        <div className="bg-white rounded-xl">
          <div className="flex w-full justify-between items-center gap-4 p-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-gray-700">
              Your Partners
            </h3>

            <RedirectLink
              text="See all"
              onClick={() => navigate("/partners")}
            />
          </div>

          <div className="p-4 grid grid-row-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {partnersListing?.slice(0, 6).map((item) => {
              const location = [];
              return (
                <PartnerCard
                  key={item?.id}
                  src=""
                  name={
                    (item?.first_name || "") + " " + (item?.last_name || "")
                  }
                  designation={item?.profession}
                  address={location?.filter(Boolean)?.join(", ")}
                  onClick={() => {
                    // setOpenPartnerModal(true);
                    // getPartnerDetails(item?.id);
                    navigate(`/partners/partner-detail/${item?.id}`);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* Projects  */}
      <div className="py-6">
        <div className="bg-white rounded-xl">
          <div className="flex w-full justify-between items-center gap-4 p-4 border-b border-gray-200">
            <h3 className="text-xl font-semibold text-gray-700">Projects</h3>

            <RedirectLink
              text="See all"
              onClick={() => navigate("/projects")}
            />
          </div>

          <div className="py-4 flex gap-3 overflow-x-auto no-scrollbar mx-4">
            {projects.map((project) => (
              <div
                key={project.id}
                className="bg-white border border-gray-200 min-w-[400px] max-h-[400px] p-6 rounded-lg shadow-lg flex flex-col justify-between"
              >
                <h1 className="text-lg font-semibold mb-4 text-gray-800 truncate">
                  {project.title}
                </h1>

                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                  <div
                    className="bg-secondary h-2.5 rounded-full"
                    style={{ width: `${project.progress}%` }}
                  ></div>
                </div>

                <div className="flex items-center mb-4">
                  {project.teamMembers.map((member, index) => (
                    <div
                      key={index}
                      className="w-8 h-8 rounded-full overflow-hidden bg-gray-400 flex items-center justify-center"
                    >
                      <img
                        src={`https://randomuser.me/api/portraits/men/${index}.jpg`}
                        alt={member}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                  {project.teamMembers.length < 4 && (
                    <div className="ml-2 flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full text-gray-600">
                      +{4 - project.teamMembers.length}
                    </div>
                  )}
                </div>

                <p className="text-gray-600 text-sm mb-2">Project Timeline</p>
                <p className="text-base font-semibold mb-4">
                  {project.timeline}
                </p>

                <div className="flex items-center text-gray-600 text-sm">
                  <p>Assigned by {project.assignedBy}</p>
                  <div
                    // key={index}
                    className="w-8 h-8 rounded-full overflow-hidden bg-gray-400 flex items-center justify-center"
                  >
                    <img
                      src={`https://randomuser.me/api/portraits/men/${8}.jpg`}
                      // alt={member}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
