import * as React from "react";

export const BellIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 19}
    height={height || 20}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.944 14.4944C17.4237 13.5981 16.6503 11.0622 16.6503 7.75C16.6503 5.76088 15.8601 3.85322 14.4536 2.4467C13.0471 1.04018 11.1394 0.25 9.15028 0.25C7.16116 0.25 5.2535 1.04018 3.84698 2.4467C2.44046 3.85322 1.65028 5.76088 1.65028 7.75C1.65028 11.0631 0.875906 13.5981 0.355593 14.4944C0.222722 14.7222 0.152282 14.9811 0.151376 15.2449C0.150471 15.5086 0.219132 15.768 0.350436 15.9967C0.481739 16.2255 0.671043 16.4156 0.899254 16.5478C1.12747 16.6801 1.38652 16.7498 1.65028 16.75H5.47622C5.64925 17.5967 6.10943 18.3577 6.77891 18.9042C7.44838 19.4507 8.28607 19.7492 9.15028 19.7492C10.0145 19.7492 10.8522 19.4507 11.5217 18.9042C12.1911 18.3577 12.6513 17.5967 12.8243 16.75H16.6503C16.914 16.7496 17.1729 16.6798 17.401 16.5475C17.6291 16.4151 17.8182 16.225 17.9494 15.9963C18.0806 15.7676 18.1492 15.5083 18.1482 15.2446C18.1473 14.9809 18.0769 14.7222 17.944 14.4944ZM9.15028 18.25C8.68511 18.2499 8.23142 18.1055 7.85165 17.8369C7.47189 17.5683 7.18471 17.1886 7.02966 16.75H11.2709C11.1158 17.1886 10.8287 17.5683 10.4489 17.8369C10.0691 18.1055 9.61545 18.2499 9.15028 18.25ZM1.65028 15.25C2.37216 14.0088 3.15028 11.1325 3.15028 7.75C3.15028 6.1587 3.78242 4.63258 4.90764 3.50736C6.03286 2.38214 7.55898 1.75 9.15028 1.75C10.7416 1.75 12.2677 2.38214 13.3929 3.50736C14.5181 4.63258 15.1503 6.1587 15.1503 7.75C15.1503 11.1297 15.9265 14.0059 16.6503 15.25H1.65028Z"
      fill="currentColor"
    />
  </svg>
);
