import * as React from "react";

export const CrossIcon = ({ width, height, ...props }) => (
  <svg
    width={width || 20}
    height={height || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 6L18.7742 18.7742"
      stroke="currentColor"
      strokeWidth={2.}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 18.7742L18.7742 5.99997"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
