import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  showToast,
  Skeleton,
  Spinner,
  TextInput,
} from "../../../../components/shared";
import { CustomModal } from "../../../../components/shared";
import { DeleteIcon, LongArrow } from "../../../../assets/icons";
import { AddMore } from "../../../../components/shared/AddMore";
import {
  deleteRecommendationsFunction,
  getRecommendationsFunction,
  updateRecommendationsFunction,
} from "../../../../redux/reducers/userReducers/recommendationsReducer/recommendationsReducer";
import { capitalize } from "../../../../utils/utils";
import { isError, validateEmail } from "../../../../utils/validations";
export const Recommendations = ({ formErrors }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.recommendations);
  const [openRecommendationsModal, setOpenRecommendationsModal] =
    useState(false);
  const [recommendationsList, setRecommendationsList] = useState([]);
  const [error, setError] = useState(null);

  const [newRecommendation, setNewRecommendation] = useState({
    referee: "",
    relation: "",
    phone: "",
    email: "",
    is_verified: true,
  });

  // Close the modal
  const handleCloseModal = () => {
    setOpenRecommendationsModal(false);
    setNewRecommendation({
      referee: "",
      relation: "",
      phone: "",
      email: "",
      is_verified: true,
    });
  };

  // Fetch Recommendations from the API
  const getRecommendations = async () => {
    const data = await dispatch(getRecommendationsFunction());
    setRecommendationsList(data?.payload);
  };

  // Delete a Recommendation
  const deleteRecommendation = async (id) => {
    const data = await dispatch(
      deleteRecommendationsFunction({
        id: id,
      })
    );
    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenRecommendationsModal(false)
      showToast("Recommendation Deleted Successfully", "success", {
        position: "top-center",
      });
      getRecommendations();
    }
    if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join())?.join("\n"),
        {
          position: "top-center",
        }
      );
    }
  };

  const isAddButtonDisabled =
    !newRecommendation.referee ||
    !newRecommendation.relation ||
    !newRecommendation.phone ||
    !newRecommendation.email;

  // Add a New Recommendation
  const addRecommendation = async () => {
    let errors = {
      email: "",
    };
    // Validate Email
    if (!validateEmail(newRecommendation?.email)) {
      errors.email = "Invalid Email";
    }
    if (isError(errors)) {
      setError(errors?.email);
    } else {
      const data = await dispatch(
        updateRecommendationsFunction(newRecommendation)
      );

      if (data?.meta?.requestStatus === "fulfilled") {
        setOpenRecommendationsModal(false);
        showToast("Recommendation Added Successfully", "success", {
          position: "top-center",
        });
        setNewRecommendation({
          referee: "",
          relation: "",
          phone: "",
          email: "",
          is_verified: true,
        });
        getRecommendations();
      }
      if (data?.error) {
        showToast(
          data?.payload
            ?.map((error) => Object.values(error).join())
            ?.join("\n"),
          {
            position: "top-center",
          }
        );
      }
    }
  };

  useLayoutEffect(() => {
    getRecommendations();
  }, []);

  // Handle form field changes
  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    // Check for only 20 characters in phone number
    if (name === "phone" && value.length > 14) return;

    if (name === "email") {
      setError(null);
    }
    
    setNewRecommendation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Recommendations</h3>
        </div>
        <AddMore
          size={"size-8"}
          onClick={() => setOpenRecommendationsModal(true)}
        />
      </div>

      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col border-b border-grey/30 last:border-0 py-2">
          {loading ? (
            <>
              {Array.from({ length: 3 }, (_, index) => (
                <div
                  key={index}
                  className="border-b border-grey/30 last:border-0 py-5"
                >
                  <Skeleton key={index} className="w-32 h-4 rounded-full" />

                  <div className="vertical-center gap-2 mt-2">
                    <Skeleton key={index} className="w-20 h-3 rounded-full" />
                    <Skeleton key={index} className="w-20 h-3 rounded-full" />
                  </div>
                  <Skeleton
                    key={index}
                    className="w-28 h-3 rounded-full mt-2"
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {recommendationsList?.length ? (
                <>
                  {recommendationsList?.map((recommendation, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 py-4"
                      >
                        <div className="font-nunito">
                          <div className="vertical-center justify-between">
                            <h2 className="text-lg">
                              {capitalize(recommendation?.referee)}
                            </h2>
                            <DeleteIcon
                              className="cursor-pointer hover:text-danger"
                              onClick={() =>
                                deleteRecommendation(recommendation?.id)
                              }
                            />
                          </div>
                          <div className="vertical-center gap-2 text-sm text-grey">
                            <p>{recommendation?.relation}</p>
                            {"|"}
                            <p>{recommendation?.phone}</p>
                          </div>
                          <p className="text-sm text-grey">
                            {recommendation?.email}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold my-1">
                  No Recommendations Added
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={"Recommendations"}
        open={openRecommendationsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
          <p className="font-bold text-sm text-primary">Your Referrals</p>
        </div>

        <div className="vertical-center gap-3">
          <p className="font-nunito text-lg font-bold">Name of Referee</p>
          {loading ? <Spinner /> : null}
        </div>
        <form
          className="flex flex-col gap-5 mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            addRecommendation();
          }}
        >
          <TextInput
            label="Referee Name"
            type="text"
            name="referee"
            value={newRecommendation.referee}
            onChange={handleFieldChange}
            error={formErrors?.referee}
            errorMsg={formErrors?.referee}
            required={true}
          />
          <TextInput
            label="Relation"
            type="text"
            name="relation"
            value={newRecommendation.relation}
            onChange={handleFieldChange}
            error={formErrors?.relation}
            errorMsg={formErrors?.relation}
            required={true}
          />
          <TextInput
            label="Phone Number"
            type="number"
            name="phone"
            value={newRecommendation.phone}
            onChange={handleFieldChange}
            error={formErrors?.phone}
            errorMsg={formErrors?.phone}
            required={true}
          />
          <TextInput
            label="Email"
            type="email"
            name="email"
            value={newRecommendation.email}
            onChange={handleFieldChange}
            error={formErrors?.email || error}
            errorMsg={formErrors?.email || error}
            required={true}
          />

          <AddMore
            text={"Add Recommendation"}
            onClick={addRecommendation}
            disabled={loading || isAddButtonDisabled}
          />
        </form>
      </CustomModal>
    </div>
  );
};
