import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Local Imports
import { CustomModal, CustomPopover, CustomTable, showToast } from '../../../components/shared';
import { usersColumnData } from '../../../utils/mockupData';
import { DeleteIcon, DotsIcon, ProfileIcon } from '../../../assets/icons';
import { deleteUserFunction, usersListingFunction } from '../../../redux/reducers/adminReducers/usersReducer';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { usersListingLoading, userDetailLoading } = useSelector(state => state?.usersListing);
  const [users, setUsers] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null
  });


  // Initial Popover Data
  const popoverData = [
    {
      name: 'Profile',
      icon: <ProfileIcon width={20} height={20} />,
      onClick: (row) => {
        setOpenPopover(null)
        navigate(`/users/user-detail/${row?.id}`)
      }
    },
    {
      name: 'Delete',
      icon: <DeleteIcon width={19} height={19} />,
      onClick: (row) => {
        setOpenPopover(null)
        setOpenModal({ open: true, data: row })
      }
    },
  ]

  const getUsers = async () => {
    const response = await dispatch(usersListingFunction());
    const data = response?.payload?.map(data => {
      return {
        id: data?.id,
        name: data?.full_name,
        email: data?.email,
        phone: data?.phone_no,
        status: data?.is_active,
      }
    })
    setUsers(data)
  }

  useLayoutEffect(() => {
    getUsers()
  }, [])

  // Delete User Function
  const deleteUser = async (id) => {
    const data = await dispatch(deleteUserFunction({ id: id }));
    if (data?.meta?.requestStatus === 'rejected') {
      const errorMessages = data?.payload ? data?.payload : 'Something went wrong';
      showToast(errorMessages, 'error', { position: 'top-center' });
      return false;
    }
    if (data?.meta?.requestStatus === 'fulfilled') {
      setOpenModal({ open: false, data: null })
      showToast('User Deleted Successfully', 'success', { position: 'top-center' });
      getUsers();
    }
  }

  return (
    <div className='py-3 px-3'>
      <CustomTable
        rowsData={users?.length > 0 ? users : []}
        columns={usersColumnData}
        loading={usersListingLoading}
        dataProviders={[
          {
            columnName: 'actions',
            component: ({ row }) => {
              return (
                <div aria-hidden="false" className='vertical-center gap-2'>
                  <CustomPopover
                    open={openPopover === row?.id}
                    setOpen={(isOpen) =>
                      setOpenPopover(isOpen ? row?.id : null)
                    }
                    trigger={
                      <div>
                        <DotsIcon />
                      </div>
                    }
                  >
                    <div className='bg-white py-2 px-2 w-32 rounded-md font-nunito text-sm flex flex-col gap-2'>
                      {popoverData?.map((data, index) => {
                        const { name, icon, onClick } = data
                        return (
                          <div
                            key={index}
                            className='vertical-center gap-2 cursor-pointer'
                            onClick={() => onClick(row)}
                          >
                            {icon}
                            <p>{name}</p>
                          </div>
                        )
                      })}
                    </div>
                  </CustomPopover>
                </div>
              )
            }
          },
          {
            columnName: 'status',
            component: ({ row }) => {
              return (
                <div className={`center rounded-full gap-2 w-fit px-3 select-none ${row?.status ? 'bg-success/80' : 'bg-danger/80'}`}>
                  <p className='text-sm text-white'>{row?.status ? "Active" : "Inactive"}</p>
                </div>
              )
            }
          }
        ]}
      />
      <CustomModal
        title={'Delete User'}
        open={openModal?.open}
        close={() => setOpenModal({ open: false, data: null })}
        primaryButtonText='Delete'
        primaryButtonColor='danger'
        primaryButtonClick={() => deleteUser(openModal?.data?.id)}
        primaryButtonLoading={userDetailLoading}
      >
        <p className='text-sm'>Are you sure you want to delete <span className='font-semibold'>{openModal?.data?.name}</span>?</p>
      </CustomModal>
    </div>
  )
}

export default Users