import React, { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { getUserDetailFunction } from '../../../redux/reducers/adminReducers/usersReducer';
import { Chip, Divider, showToast, Skeleton } from '../../../components/shared';
import { Avatar } from '@nextui-org/react';
import { capitalize, formatDate } from '../../../utils/utils';
import { BagIcon, BirthdayIcon, EditIcon, LocationIcon, LongArrow, MailIcon, PhoneIcon, RecommendationIcon } from '../../../assets/icons';
import { assets } from '../../../assets/images';
import { Experiences } from './Experiences';
import { Projects } from './Projects';
import { Certifications } from './Certifications';
import Licences from './Licences';
import { Skills } from './Skills';
import { Recommendations } from './Recommentdations';

const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetail: profileDetails, userDetailLoading, userDetailError } = useSelector(state => state?.usersListing);

  // Get User Details
  const getUserDetails = async () => {
    const data = await dispatch(getUserDetailFunction({ id: id }));

    if (data?.meta?.requestStatus === 'rejected') {
      const errorMessages = data?.payload ? data?.payload : 'Something went wrong';
      showToast(errorMessages, 'error', { position: 'top-center' });
      return false;
    }
  }

  useLayoutEffect(() => {
    getUserDetails();
  }, [])


  return (
    <>
      {userDetailLoading ? (
        <div className="relative pb-10 mx-3 mt-3 font-nunito border border-grey/30 rounded-2xl overflow-hidden">
          <div className='z-10 relative pl-6 py-6 pb-[4.5rem]'>
            <h3 className='text-xl'>Profile</h3>
          </div>
          <img src={assets?.coverbg} loading='lazy' alt="cover-image" className="w-full absolute top-0 left-0 z-0 h-48 object-cover rounded-t-xl" />
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-baseline justify-center sm:justify-between relative z-10 mb-5">
              <div className='vertical-center justify-center border-4 border-white bg-white rounded-full z-10'>
                <Skeleton className='size-32 rounded-full' />
              </div>
              {/* <div className="hidden sm:vertical-center gap-2 font-semibold cursor-pointer select-none hover:text-secondary transition">
                <Skeleton className='w-32 h-6 rounded-full' />
              </div> */}
            </div>

            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div className='flex flex-col items-center sm:items-start gap-2'>
                <Skeleton className='w-40 h-6 rounded-full' />
                <Skeleton className='w-28 h-5 rounded-full' />
              </div>
              <div className="sm:hidden center gap-2 font-semibold cursor-pointer hover:text-secondary transition">
                <Skeleton className='w-32 h-6 rounded-full' />
              </div>

              <Skeleton className='w-40 h-14 rounded-full' />
            </div>

            <div className='smd:grid grid-cols-12 gap-1'>
              <div className='flex flex-col gap-4 w-full col-start-1 col-span-6 max-smd:mt-10'>
                <Skeleton className='w-60 h-3 rounded-full' />
                <Skeleton className='w-60 h-3 rounded-full' />
                <Skeleton className='w-60 h-3 rounded-full' />
              </div>

              <div className='w-full max-smd:mt-10 col-span-7 smd:col-span-5 md:col-span-4 lg:col-span-3 col-start-7 smd:col-start-8 md:col-start-9 lg:col-start-10'>
                <div>
                  <p className='text-md font-semibold'>Role</p>
                  <Divider />
                  <div className='mt-3 flex flex-wrap items-center gap-2'>
                    {Array.from({ length: 3 }, (_, index) => (
                      <Skeleton key={index} className="flex rounded-full w-24 h-8" />
                    ))}
                  </div>
                </div>
                <div className='mt-4'>
                  <p className='text-md font-semibold'>Interests</p>
                  <Divider />
                  <div className='mt-3 flex flex-wrap items-center gap-2'>
                    {Array.from({ length: 3 }, (_, index) => (
                      <Skeleton key={index} className="flex rounded-full w-24 h-8" />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative pb-10 font-nunito mx-3 mt-3 border border-grey/30 rounded-2xl overflow-hidden">
          <div className='z-10 relative pl-6 py-6 pb-[4.5rem]'>
            <div className='vertical-center gap-3'>
              <LongArrow
                className='rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition'
                onClick={() => navigate(-1)}
              />
              <h3 className='text-xl'>Profile</h3>
            </div>
          </div>
          <img src={assets?.coverbg} loading='lazy' alt="cover-image" className="w-full absolute top-0 left-0 z-0 h-48 object-cover rounded-t-xl" />
          <div className="w-full mx-auto px-6 md:px-8">
            <div className="flex items-baseline justify-center sm:justify-between relative z-10 mb-5">
              <div className='vertical-center justify-center border-4 border-white rounded-full z-10'>
                <Avatar
                  className='!size-32'
                  color="secondary"
                />
              </div>
              {/* <div
                className="hidden sm:vertical-center gap-2 font-semibold cursor-pointer select-none hover:text-secondary transition"
              // onClick={() => setOpenProfileModal(true)}
              >
                <EditIcon />
                <p>Edit Profile</p>
              </div> */}
            </div>

            <div className="flex flex-col sm:flex-row max-sm:gap-5 items-center justify-between mb-5">
              <div className='flex flex-col items-center sm:items-start'>
                <h3 className="font-manrope font-bold text-2xl">{profileDetails?.full_name?.split(" ")?.map((word) => capitalize(word)).join(" ")}</h3>
                {profileDetails?.mailing_address?.physical_address ? (
                  <div className='vertical-center gap-2 text-grey'>
                    <LocationIcon width={16} height={16} />
                    <p className="font-normal text-base leading-7 text-grey">{profileDetails?.mailing_address?.physical_address}</p>
                  </div>
                ) : null}
              </div>
              {/* <div
                className="sm:hidden center gap-2 font-semibold cursor-pointer hover:text-secondary transition"
              // onClick={() => setOpenProfileModal(true)}
              >
                <EditIcon />
                <p>Edit Profile</p>
              </div> */}

              {profileDetails?.profession ? (
                <div
                  className="rounded-full py-3 px-4 bg-bggrey/80 hover:bg-primary/20 hover:text-secondary vertical-center transition">
                  <BagIcon />
                  <span className="px-2 font-medium text-base leading-7">{profileDetails?.profession}</span>
                </div>
              ) : null}
            </div>

            <div className='smd:grid grid-cols-12 gap-1 mb-6'>
              <div className='flex flex-col gap-4 w-full col-start-1 col-span-6 max-smd:mt-10'>
                <div className='vertical-center gap-2'>
                  <MailIcon width={18} height={18} />
                  <p className='text-[15px] text-darkgrey leading-4'>{profileDetails?.email}</p>
                </div>

                <div className='vertical-center gap-2'>
                  <PhoneIcon width={19} height={19} />
                  <p className='text-sm text-darkgrey leading-4'>{profileDetails?.phone_no}</p>
                </div>

                {profileDetails?.birthday ? (
                  <div className='vertical-center gap-2'>
                    <BirthdayIcon width={19} height={19} />
                    <p className='text-sm text-darkgrey leading-4'>{formatDate(profileDetails?.birthday)}</p>
                  </div>
                ) : null}
              </div>

              <div className='w-full max-smd:mt-10 col-span-7 smd:col-span-5 md:col-span-4 lg:col-span-3 col-start-7 smd:col-start-8 md:col-start-9 lg:col-start-10'>
                <div>
                  <p className='text-md font-semibold'>Role</p>
                  <Divider />
                  <div className='mt-3 flex flex-wrap items-center gap-2'>
                    {profileDetails?.user_roles && profileDetails?.user_roles.map((role, index) => (
                      <p key={index} className='text-sm bg-gold/30 rounded-full w-fit px-3 py-1'>{role}</p>
                    ))}
                  </div>
                </div>
                <div className='mt-4'>
                  <p className='text-md font-semibold'>Interests</p>
                  <Divider />
                  <div className='mt-3 flex flex-wrap items-center gap-2'>
                    {profileDetails?.user_interests && profileDetails?.user_interests?.map((interest, index) => (
                      <p key={index} className='text-sm bg-gold/30 rounded-full w-fit px-3 py-1'>{interest}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <Divider />

            {/* Experiences */}
            {profileDetails?.experiences?.length > 0 ? (
              <div className='my-6'>
                <p className='text-lg font-semibold'>Experiences</p>
                <Experiences
                  loading={userDetailLoading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                />
              </div>
            ) : null}

            <Divider />

            {/* Projects */}
            {profileDetails?.project_experiences?.length > 0 ? (
              <div className='my-6'>
                <p className='text-lg font-semibold'>Projects</p>
                <Projects
                  loading={userDetailLoading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                />
              </div>
            ) : null}

            <Divider />

            {/* Certifications */}
            {profileDetails?.certifications?.length > 0 ? (
              <div className='my-6'>
                <p className='text-lg font-semibold'>Certifications</p>
                <Certifications
                  loading={userDetailLoading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                />
              </div>
            ) : null}

            <Divider />

            {/* Licences */}
            {profileDetails?.licenses?.length > 0 ? (
              <div className='my-6'>
                <p className='text-lg font-semibold'>Licences</p>
                <Licences
                  loading={userDetailLoading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                />
              </div>
            ) : null}

            <Divider />

            {/* Recommendations */}
            {profileDetails?.recommendations?.length > 0 ? (
              <div className='my-6'>
                <p className='text-lg font-semibold'>Recommendations</p>
                <Recommendations
                  loading={userDetailLoading}
                  profileData={profileDetails}
                  getUserDetails={getUserDetails}
                />
              </div>
            ) : null}

            <Divider />

            {/* Skill */}
            {profileDetails?.skills?.length > 0 ? (
              <div className='my-6'>
                <p className='text-lg font-semibold'>Skills</p>
                <Skills
                  loading={userDetailLoading}
                  skills={profileDetails?.skills}
                />
              </div>
            ) : null}
          </div>
        </div >
      )}
    </>
  )
}

export default UserDetails