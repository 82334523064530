export const DotsIcon = ({ width, height, ...props }) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || 20}
    width={width || 20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
          fill='currentColor'
      d="M10 12a2 2 0 1 1 0-4a2 2 0 0 1 0 4m0-6a2 2 0 1 1 0-4a2 2 0 0 1 0 4m0 12a2 2 0 1 1 0-4a2 2 0 0 1 0 4"
    />
  </svg>
);
