import { assets } from "../assets/images";

export const usersColumnData = [
  { name: 'name', title: 'Name', width: 2000, sorting: false },
  { name: 'email', title: 'Email', width: 2000, sorting: false },
  { name: 'phone', title: 'Phone', width: 2000, sorting: false },
  { name: 'status', title: 'Status', width: 2000, sorting: false },
  { name: 'actions', title: 'Actions', width: 2000, },
];

// export const userTableColumnExtensions = [
//   { columnName: 'name', width: 170 },
//   { columnName: 'email', width: 100 },
//   { columnName: 'phone', width: 120 },
//   { columnName: 'status', width: 120 },
//   { columnName: 'action', width: 50 },
// ];

export const users = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: `User ${index + 1}`,
  email: `user${index + 1}@example.com`,
  phone: `+1 234 5678${index + 1}9`,
  status: index % 2 === 0 ? 'Active' : 'Inactive',
}));


export const jobTypeData = [
  { id: 1, name: 'Full Time' },
  { id: 2, name: 'Part Time' },
  { id: 3, name: 'Internship' },
  { id: 4, name: 'Contract' },
]

// Suggested Partners Data
export const partners = [
  { id: 1, name: 'Gregory', designation: 'Lender', image: assets.lender1 },
  { id: 2, name: 'David', designation: 'Laywer', image: assets.lender2 },
  { id: 3, name: 'Hathaway', designation: 'Banker', image: assets.lender3 },
  { id: 4, name: 'Hathaway', designation: 'Banker', image: assets.lender4 },
  { id: 5, name: 'Hathaway', designation: 'Banker', image: assets.lender5 },
  { id: 6, name: 'Hathaway', designation: 'Banker', image: assets.lender6 },
  { id: 7, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 8, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 9, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 10, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 11, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 12, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 13, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 14, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 15, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 16, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 17, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 18, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 19, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
  { id: 20, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
];

// Nearest Partner Data
export const nearestPartners = [
  { id: 1, name: 'Gregory', designation: 'Lender', address: 'Lagos, Nigeria', image: assets.lender1 },
  { id: 2, name: 'David', designation: 'Laywer', address: 'Lagos, Nigeria', image: assets.lender2 },
  { id: 3, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender3 },
  { id: 4, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender4 },
  { id: 5, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender5 },
  { id: 6, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 7, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 8, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 9, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 10, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 11, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 12, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 13, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 14, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 15, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 16, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 17, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 18, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 19, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
  { id: 20, name: 'Hathaway', designation: 'Banker', address: 'Lagos, Nigeria', image: assets.lender6 },
];


export const verificationOptions = [
  { id: 'PENDING', name: 'Pending' },
  { id: 'UNVERIFIED', name: 'Unverified' },
  { id: 'VERIFIED', name: 'Verified' },
]