import React from "react";
import { AvatarWithDetails } from "../AvatarWithDetails";
import { useNavigate } from "react-router-dom";

export const TopRatedCard = ({ list }) => {
  const navigate = useNavigate();
  return (
    <div className="flex space-x-4">
      {list?.map((item) => {
        return (
          <div
            key={item?.id}
            className="w-28 h-40 flex justify-center items-baseline pt-6 rounded-lg bg-white border-2 border-lightgray transition duration-300 ease-in-out transform hover:shadow-lg hover:border-lightblue cursor-pointer"
            onClick={() => navigate(`/partners/partner-detail/${item?.id}`)}
          >
            <AvatarWithDetails
              src={`https://randomuser.me/api/portraits/men/${item.id}.jpg`}
              name={item?.first_name}
              designation={item?.profession}
            />
          </div>
        );
      })}
    </div>
  );
};
