import * as React from 'react';
export const SecurityIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13 2.5H3C2.73478 2.5 2.48043 2.60536 2.29289 2.79289C2.10536 2.98043 2 3.23478 2 3.5V7C2 10.295 3.595 12.2919 4.93313 13.3869C6.37437 14.5656 7.80813 14.9656 7.87063 14.9825C7.95656 15.0059 8.04719 15.0059 8.13313 14.9825C8.19563 14.9656 9.6275 14.5656 11.0706 13.3869C12.405 12.2919 14 10.295 14 7V3.5C14 3.23478 13.8946 2.98043 13.7071 2.79289C13.5196 2.60536 13.2652 2.5 13 2.5ZM10.855 6.85375L7.355 10.3538C7.30856 10.4002 7.25342 10.4371 7.19272 10.4623C7.13202 10.4874 7.06696 10.5004 7.00125 10.5004C6.93554 10.5004 6.87048 10.4874 6.80978 10.4623C6.74908 10.4371 6.69394 10.4002 6.6475 10.3538L5.1475 8.85375C5.05368 8.75993 5.00097 8.63268 5.00097 8.5C5.00097 8.36732 5.05368 8.24007 5.1475 8.14625C5.24132 8.05243 5.36857 7.99972 5.50125 7.99972C5.63393 7.99972 5.76118 8.05243 5.855 8.14625L7 9.29313L10.1462 6.14625C10.1927 6.09979 10.2479 6.06294 10.3086 6.0378C10.3692 6.01266 10.4343 5.99972 10.5 5.99972C10.5657 5.99972 10.6308 6.01266 10.6914 6.0378C10.7521 6.06294 10.8073 6.09979 10.8538 6.14625C10.9002 6.1927 10.9371 6.24786 10.9622 6.30855C10.9873 6.36925 11.0003 6.4343 11.0003 6.5C11.0003 6.5657 10.9873 6.63075 10.9622 6.69145C10.9371 6.75214 10.9002 6.8073 10.8538 6.85375H10.855Z'
      fill={color || 'currentColor'}
    />
  </svg>
);
